import SnowieLogo from '../../assets/images/snowie_logo_letters.png'
import { useState, useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import './index.scss'
import Bus from './Bus'
import AnimatedLetters from '../AnimatedLetters'
import Loader from '../Loader/loader'

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const shermanArray = ['O', 'F', ' ', 'S', 'H', 'E', 'R', 'M', 'A', 'N']

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLetterClass('text-animate-hover')
    }, 3000)
    return () => clearTimeout(timeout)
    }, [])

    return (
        <>
        <div className='container home-page'>
            <div className='text-zone'>
                <h1>
                <img id='snowie-logo' src={SnowieLogo} alt='Snowie Logo' />
                <br />
                <div id='sherman'>
                    <AnimatedLetters letterClass={letterClass} 
                                    letterArray={shermanArray} 
                                    index={15} />
                </div>
                </h1>
                <h2>Events, Parties, and More!</h2>
                <Link to='/contact' className='flat-button'>CONTACT US</Link>
                <Link to='/photos' className='flat-button menu-button'>MENU</Link>
            </div>
            <Bus />
        </div>
        <Loader />
        </>
        )
}




export default Home