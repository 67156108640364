import { useState, useEffect } from 'react'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import Loader from '../Loader/loader'
import Carousel from './Carousel/index.js'
import Tabs from './TabComponent/Tabs.js'

const Photos = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const fancyArray = ['F', 'A', 'N', 'C', 'Y', ' ', 'F', 'U', 'S', 'I', 'O', 'N', 'S']
    const menuArray = ['M', 'E', 'N', 'U']

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLetterClass('text-animate-hover')
    }, 3000)
    return () => clearTimeout(timeout)
    }, [])


    return (
        <>
            <div className='container photos-page' id="photos-container">
                <div className='text-zone'>
                    <h1>
                        <div id='social-intro'>
                            <AnimatedLetters letterClass={letterClass}
                                            letterArray={fancyArray}
                                            index={17} />
                        </div>
                    </h1>

                    <div className='photo-holder'>
                        <Carousel />
                    </div>

                    <hr />

                    <div className='menu-holder'>
                        <h1>
                            <AnimatedLetters letterClass={letterClass}
                                                    letterArray={menuArray}
                                                    index={17} />
                        </h1>
                        <div className='tabs-holder'>
                            <Tabs />
                        </div>
                        
                    </div>
                </div>
            </div>
        <Loader />
        </>
        )
}

export default Photos;