import './loader.scss'

const Loader = () => {
    return (
        <div className='loader-holder'>

            {/* Ice Cream Loader
            <div className='cup'>
                <svg viewBox="0 0 100 200">
                    <defs>
                        <linearGradient id="Gradient">
                            <stop class="stop1" offset="0%" />
                            <stop class="stop2" offset="50%" />
                            <stop class="stop3" offset="100%" />
                        </linearGradient>
                    </defs>
                    <path d="M 0 0 L 100 0 L 50 200 Z"/>
                </svg>
            </div>
            <div className='scoop white'></div>
            <div className='scoop rainbow-wave'></div> */}


            <div className='ball-bounce'>
                <div className='ball1'></div>
                <div className='ball2'></div>
                <div className='ball3'></div>
            </div>
        </div>
    )
}

export default Loader