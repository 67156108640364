import React from 'react'

import Fusions from '../../../../assets/images/snowcones/Fusions.png'

const SecondTab = () => {
    return (
        <div className='second-tab-content'>
            <img src={Fusions} alt='List of Fusions' />
        </div>
    )
}

export default SecondTab