const Snowflakes = () => {
    function createSnow() {
        const snow = document.createElement("div");
        var size = Math.random() * 10 + 10;

        snow.innerHTML = "<img id='snowflake' src='snowflake.png' style='width:" + size + "px' />"
        snow.classList.add('snow');
    
        document.getElementById('snow-container').appendChild(snow);
    
        snow.style.left = Math.random() * 100 + 'vw';
    
        snow.style.animationDuration = Math.random() * 5 + 8 + 's';
    
        setTimeout(() => {
        snow.remove();
        }, 8000);
    }
    
    setInterval(createSnow, 1600);
}

export default Snowflakes;