import './index.scss'
// import { useEffect, useRef } from 'react'
// import DrawSVGPlugin from 'gsap-trial/DrawSVGPlugin'
// import gsap from 'gsap-trial'

const Bus = () => {

    // const bgRef = useRef();

    // useEffect(() => {
    //     gsap.registerPlugin(DrawSVGPlugin)

    //     gsap
    //     .timeline()
    //     .to(bgRef.current, {
    //         duration: 1,
    //         opacity: 1,
    //     })
    //     .fromTo('.draw-me', {
    //         drawSVG: 0,
            
    //     }, {
    //         drawSVG: '100%',
    //         duration: 10,
    //     })
    // }, [])

    return (
        // <div className='bus-container' ref={bgRef}> also change css opacity to 0 again
        <div className='bus-container'>
            <svg id='bus-svg' version='1.0' xmlns='http://www.w3.org/2000/svg'
            width='300.000000pt' height='386.000000pt' viewBox='0 0 300.000000 386.000000'
            preserveAspectRatio='xMidYMid meet'>
                <g transform='translate(0.000000,386.000000) scale(0.100000,-0.100000)'
                    className='svg-container' fill='none'>
                <path className='draw-me' d='M667 3626 c-15 -7 -45 -29 -68 -50 -78 -74 -107 -165 -118 -366 -5
                -85 -4 -147 1 -151 12 -7 13 -34 5 -84 -4 -27 -4 -34 3 -20 7 17 9 16 9 -11 1
                -16 -3 -34 -8 -40 -13 -12 -17 -138 -5 -144 5 -3 12 24 16 60 4 36 11 66 17
                68 5 2 7 12 4 22 -3 12 1 22 11 26 9 3 16 11 16 16 0 6 -7 7 -16 4 -14 -6 -16
                -1 -10 35 5 29 3 45 -6 50 -15 10 -19 144 -6 219 6 30 11 64 11 76 1 11 8 28
                14 36 7 8 10 18 6 21 -3 4 -1 7 6 7 9 0 11 -8 7 -24 -3 -14 -1 -37 5 -53 6
                -15 13 -64 15 -109 1 -45 10 -111 18 -148 28 -118 106 -247 177 -289 41 -25
                42 -37 2 -37 -18 0 -23 -5 -23 -25 0 -25 15 -35 22 -14 2 6 40 15 85 19 88 9
                131 26 161 65 11 14 30 26 43 26 18 1 20 2 6 6 -10 3 -16 10 -13 16 7 18 24
                77 35 127 9 38 10 31 6 -49 -4 -82 -3 -92 11 -87 9 3 24 6 33 6 16 0 19 13 24
                103 7 124 -5 231 -37 325 -82 240 -222 383 -373 381 -32 0 -70 -6 -86 -13z
                m-13 -70 c-3 -8 -12 -17 -20 -20 -8 -3 -14 -12 -14 -20 0 -8 -5 -18 -12 -22
                -7 -4 -8 -3 -4 4 4 7 3 12 -2 12 -5 0 -9 7 -9 15 0 8 4 12 9 8 5 -3 18 4 29
                16 23 24 31 27 23 7z m227 -37 c29 -18 59 -34 67 -35 22 -4 53 -36 74 -79 19
                -40 19 -40 -1 -17 -26 30 -35 28 -18 -5 48 -91 102 -283 76 -267 -5 3 -9 13
                -9 22 0 9 -4 23 -9 30 -5 8 -8 -31 -8 -95 1 -113 -8 -173 -25 -173 -6 0 -8
                -12 -4 -27 6 -25 5 -26 -6 -8 -12 21 -28 35 -41 35 -5 0 1 -13 12 -29 23 -32
                28 -52 9 -33 -15 15 -32 16 -23 2 4 -6 1 -18 -7 -27 -12 -15 -11 -15 9 -5 30
                16 44 15 37 -3 -15 -38 -122 -73 -167 -55 -40 16 -108 86 -101 104 3 9 0 24
                -7 33 -10 11 -11 17 -3 20 7 2 9 14 6 26 l-5 22 25 -22 c30 -28 42 -30 26 -3
                -12 19 -67 45 -75 36 -3 -2 0 -13 6 -24 6 -11 8 -27 5 -35 -3 -8 -1 -18 5 -22
                15 -9 14 -38 -1 -32 -7 2 -12 10 -10 16 1 6 -2 11 -8 11 -13 0 -53 80 -80 160
                -26 79 -28 223 -5 316 16 59 64 131 84 127 6 -2 11 1 11 7 0 16 65 22 108 10
                39 -10 58 -30 30 -30 -7 0 -4 -7 7 -15 41 -30 66 -51 83 -67 17 -16 18 -16 20
                8 1 14 -2 23 -8 19 -6 -3 -10 1 -10 10 0 10 -5 13 -12 9 -6 -4 -8 -3 -5 3 9
                14 -41 59 -85 77 -72 31 -149 12 -199 -47 -21 -25 -60 -122 -56 -138 1 -7 -3
                -13 -9 -16 -32 -11 24 147 69 196 56 61 143 65 228 10z m-281 -55 c0 -8 -7
                -17 -15 -20 -8 -4 -15 -13 -15 -21 0 -8 -5 -13 -11 -11 -12 4 -12 7 2 47 11
                30 39 34 39 5z m512 -255 c13 -34 17 -78 17 -188 1 -79 -1 -142 -4 -139 -3 3
                -8 72 -11 154 -5 113 -11 158 -25 187 -11 24 -13 37 -6 34 7 -2 20 -24 29 -48z'/>
                <path className='draw-me' d='M835 3395 c-16 -49 -18 -51 -24 -25 l-7 25 -7 -25 c-5 -18 -2 -29 11
                -38 15 -11 18 -10 22 7 8 31 39 63 56 57 9 -3 12 -1 9 4 -10 16 -55 12 -60 -5z'/>
                <path className='draw-me' d='M660 3399 c0 -2 -9 -29 -20 -59 -20 -55 -29 -220 -12 -220 4 0 6 33
                4 73 -3 55 1 87 18 136 11 35 19 66 15 69 -3 3 -5 3 -5 1z'/>
                <path className='draw-me' d='M916 3361 c-3 -4 6 -19 19 -31 24 -22 25 -22 25 -4 0 31 -31 56 -44
                35z'/>
                <path className='draw-me' d='M710 3346 c-13 -13 -13 -36 1 -36 11 0 38 29 39 41 0 10 -29 6 -40
                -5z'/>
                <path className='draw-me' d='M953 3279 c7 -11 17 -19 24 -19 26 0 8 34 -19 36 -14 1 -15 -2 -5
                -17z'/>
                <path className='draw-me' d='M745 3280 c-3 -5 -2 -16 2 -24 11 -20 36 -4 27 17 -7 19 -20 22 -29
                7z'/>
                <path className='draw-me' d='M920 3280 c0 -6 6 -13 14 -16 8 -3 12 -13 8 -26 -4 -15 -2 -19 7 -14
                7 5 23 5 36 2 21 -5 23 -10 18 -44 -7 -45 2 -41 10 5 6 28 3 35 -21 47 -15 8
                -37 24 -49 36 -13 11 -23 16 -23 10z'/>
                <path className='draw-me' d='M862 3229 c1 -12 9 -24 16 -27 9 -3 13 3 10 19 -1 12 -9 24 -16 27
                -9 3 -13 -3 -10 -19z'/>
                <path className='draw-me' d='M730 3135 c-7 -8 -9 -23 -4 -37 4 -13 7 -33 5 -45 -2 -20 2 -23 29
                -23 18 0 29 4 25 10 -3 6 -15 10 -26 10 -21 0 -26 16 -6 23 10 4 10 7 0 18
                -16 16 -17 49 -2 49 6 0 9 -6 6 -13 -6 -17 20 -41 29 -26 5 8 9 8 14 -1 6 -9
                14 -8 36 4 l29 16 -47 -3 c-41 -3 -48 -1 -48 15 0 22 -23 24 -40 3z'/>
                <path className='draw-me' d='M970 3096 c0 -13 -5 -16 -19 -11 -27 8 -77 -5 -60 -16 7 -4 18 -4 24
                1 6 5 25 8 42 6 22 -3 30 0 29 10 -3 21 -16 29 -16 10z'/>
                <path className='draw-me' d='M1028 3094 c-5 -4 -8 -16 -8 -26 0 -11 -4 -16 -10 -13 -6 4 -10 -7
                -10 -24 0 -17 5 -31 11 -31 5 0 8 4 5 8 -3 5 2 21 10 37 15 29 16 64 2 49z'/>
                <path className='draw-me' d='M790 3069 c0 -19 10 -32 40 -52 27 -19 50 -22 50 -7 0 6 -7 10 -16
                10 -9 0 -29 14 -45 31 -16 17 -29 25 -29 18z'/>
                <path className='draw-me' d='M946 3044 c-9 -23 -8 -24 17 -23 21 0 21 1 2 9 -17 7 -17 9 -2 9 20
                1 24 21 3 21 -8 0 -17 -7 -20 -16z'/>
                <path className='draw-me' d='M650 3038 c0 -30 13 -58 26 -58 8 0 14 10 13 23 0 12 -3 16 -6 9 -2
                -6 -9 -10 -13 -7 -5 3 -7 12 -4 19 3 8 1 17 -5 21 -6 3 -11 0 -11 -7z'/>
                <path className='draw-me' d='M890 3028 c0 -37 47 -59 80 -38 11 7 7 10 -17 10 -21 0 -37 7 -47 22
                -13 18 -16 19 -16 6z'/>
                <path className='draw-me' d='M809 2932 c8 -6 10 -15 6 -22 -5 -8 -4 -11 3 -7 13 9 42 -2 42 -15 0
                -7 -18 -9 -45 -6 -43 5 -44 5 -22 -11 35 -27 97 -53 112 -47 8 3 17 1 20 -5
                10 -15 25 0 24 24 0 12 2 32 6 45 5 14 3 22 -4 22 -6 0 -11 -9 -11 -20 0 -18
                -5 -22 -26 -19 -3 0 -12 -7 -18 -17 -12 -16 -14 -16 -37 0 -13 10 -17 16 -9
                15 8 -1 24 2 34 6 19 8 19 9 0 17 -15 7 -13 8 8 5 22 -3 26 -1 19 11 -5 9 -17
                16 -27 17 -11 1 -35 5 -54 9 -23 5 -30 4 -21 -2z'/>
                <path className='draw-me' d='M770 2835 c7 -8 16 -12 21 -9 5 3 9 -1 9 -9 0 -10 17 -17 51 -21 28
                -4 54 -5 57 -1 3 3 -4 5 -15 5 -11 0 -42 11 -69 25 -56 29 -72 31 -54 10z'/>
                <path className='draw-me' d='M1843 3448 c-17 -6 -34 -17 -37 -25 -7 -18 4 -39 13 -26 3 6 21 13
                39 16 31 6 32 5 32 -26 0 -18 6 -42 14 -52 12 -16 14 -12 19 41 4 46 2 62 -11
                72 -18 14 -25 14 -69 0z'/>
                <path className='draw-me' d='M1806 3362 c-8 -13 -44 -175 -51 -229 -12 -90 9 -58 33 50 12 56 22
                111 22 122 0 11 7 31 16 43 13 19 13 22 0 22 -8 0 -17 -3 -20 -8z'/>
                <path className='draw-me' d='M538 3258 c-6 -14 -11 -141 -9 -200 1 -11 9 -18 21 -18 16 0 20 7 20
                33 -1 59 -26 202 -32 185z'/>
                <path className='draw-me' d='M1655 2794 c-16 -3 -183 -25 -370 -50 -187 -25 -356 -49 -375 -54
                l-35 -8 35 1 c20 0 42 -3 49 -8 8 -5 12 -4 9 1 -4 5 4 10 15 11 12 1 51 6 87
                12 177 25 249 34 290 36 25 2 113 12 195 24 189 26 179 26 172 -1 -6 -22 -5
                -22 8 -5 12 16 33 19 149 24 120 4 136 3 136 -11 0 -20 18 -21 40 -1 16 15 60
                10 60 -6 0 -12 47 -11 55 1 4 6 15 6 31 0 14 -6 41 -10 60 -10 35 0 35 -1 29
                -32 -5 -30 -5 -31 8 -15 9 11 21 15 32 10 13 -4 16 -3 11 6 -10 15 46 3 56
                -13 4 -6 8 -8 8 -3 0 32 190 -127 190 -160 0 -7 4 -13 10 -13 15 0 8 69 -8 83
                -13 10 -15 9 -8 -8 4 -11 -21 7 -56 41 -83 80 -135 101 -323 130 -87 14 -500
                27 -560 18z'/>
                <path className='draw-me' d='M1602 2730 c-16 -22 -21 -37 -16 -57 10 -41 11 -42 51 -28 32 11 38
                18 41 45 3 31 2 32 -13 17 -9 -9 -13 -22 -9 -29 4 -7 4 -10 -1 -6 -4 4 -16 3
                -26 -3 -17 -9 -19 -6 -19 25 0 19 7 42 16 50 8 9 11 16 7 16 -5 0 -19 -13 -31
                -30z'/>
                <path className='draw-me' d='M2192 2734 c-8 -9 -9 -19 -3 -31 15 -29 24 -57 22 -70 -1 -7 3 -13 8
                -13 18 0 24 49 11 83 -18 43 -23 48 -38 31z'/>
                <path className='draw-me' d='M1739 2724 c-6 -8 -8 -18 -4 -22 4 -4 10 -2 12 5 2 7 11 9 19 6 8 -3
                14 -1 14 5 0 17 -28 21 -41 6z'/>
                <path className='draw-me' d='M1400 2716 c-19 -8 -39 -10 -45 -6 -5 4 -28 5 -50 1 -29 -5 -41 -12
                -43 -26 -3 -19 -2 -19 11 -2 14 17 77 25 77 9 0 -4 -9 -15 -19 -24 -24 -20
                -37 -90 -21 -115 9 -16 10 -15 4 6 -4 14 -2 21 3 18 6 -4 15 5 22 19 13 30 15
                56 1 34 -5 -8 -12 -11 -16 -8 -3 4 1 14 9 22 10 11 17 12 19 4 3 -8 17 -7 52
                5 44 15 47 15 44 -1 -2 -10 -21 -23 -48 -31 -47 -15 -60 -26 -59 -51 1 -12 2
                -12 6 1 2 9 16 19 31 23 15 3 37 8 50 12 16 5 22 2 22 -9 0 -9 -13 -18 -31
                -22 -24 -6 -28 -9 -18 -17 10 -7 8 -8 -9 -5 -14 3 -20 1 -16 -5 5 -7 -2 -9
                -19 -6 -17 3 -28 0 -32 -10 -9 -25 3 -26 60 -8 56 17 79 35 48 37 -12 1 -10 3
                7 8 28 8 39 39 18 52 -9 6 -9 9 -1 9 8 0 12 16 10 47 -2 54 -12 59 -67 39z
                m48 -21 c-21 -20 -80 -37 -91 -26 -7 7 -1 13 14 19 13 6 35 14 49 20 30 12 47
                4 28 -13z'/>
                <path className='draw-me' d='M535 2696 c-16 -7 -39 -24 -51 -37 -23 -26 -38 -59 -26 -59 4 0 4
                -71 -1 -157 -4 -86 -3 -161 1 -165 4 -4 29 -12 55 -17 27 -5 49 -10 51 -12 2
                -2 1 -77 -2 -166 l-5 -163 -57 0 c-54 0 -58 -2 -62 -25 -6 -32 -5 -32 60 -30
                l52 1 0 -132 0 -131 -31 -7 c-17 -3 -44 -6 -60 -6 -15 0 -30 -4 -33 -8 -8 -13
                -11 -421 -3 -428 13 -13 21 6 19 46 -2 34 0 40 18 40 11 0 20 5 20 11 0 6 -9
                9 -20 6 -13 -3 -20 0 -20 9 0 8 4 14 8 14 5 0 7 20 5 45 -3 30 -1 45 7 45 8 0
                9 20 4 70 -7 77 1 87 34 45 16 -20 32 -26 78 -31 33 -4 68 -6 80 -6 11 0 24
                -10 30 -21 13 -29 31 -32 39 -8 12 39 35 25 35 -21 0 -47 18 -90 22 -53 1 11
                2 27 4 35 1 8 2 25 3 38 2 42 46 16 46 -28 0 -16 5 -30 10 -30 6 0 10 10 10
                23 0 20 5 22 50 23 28 1 68 -1 89 -3 35 -5 38 -7 23 -18 -15 -11 -15 -14 -3
                -18 8 -3 21 4 30 16 15 22 16 22 31 2 14 -18 13 -20 -10 -32 -24 -13 -25 -15
                -10 -43 8 -16 15 -38 15 -49 0 -18 -3 -19 -26 -11 -14 6 -42 9 -62 9 -31 -1
                -28 -3 18 -9 30 -5 56 -13 58 -18 2 -6 14 -6 33 1 22 9 34 9 49 -1 35 -22 40
                -14 40 61 0 80 5 92 38 94 21 2 22 -2 22 -62 0 -53 3 -63 15 -59 10 4 15 21
                15 54 0 47 11 62 38 53 6 -3 12 -18 12 -34 0 -16 3 -29 8 -29 16 0 52 32 52
                46 0 8 5 14 10 14 6 0 10 -4 10 -9 0 -6 14 -5 35 0 20 6 50 9 67 7 21 -2 32 2
                33 12 2 13 -16 15 -114 16 -64 0 -195 10 -291 23 -435 56 -558 71 -588 71 -29
                0 -32 3 -32 30 0 25 -4 30 -24 30 -42 0 -39 18 4 25 45 8 54 6 57 -13 2 -19
                10 -25 22 -17 7 4 11 60 11 153 0 81 5 257 11 391 11 217 10 245 -4 252 -9 5
                -13 17 -10 28 4 17 0 18 -44 14 -39 -5 -46 -3 -35 8 6 7 12 22 12 32 0 16 -3
                17 -15 7 -9 -8 -15 -9 -15 -2 0 6 3 13 8 16 4 2 6 32 5 65 -1 41 3 65 12 75 8
                7 11 17 7 21 -4 5 -1 5 6 1 6 -4 18 -1 26 6 8 9 33 14 65 13 28 -1 51 3 51 8
                0 5 11 7 25 5 14 -3 25 -1 25 3 0 5 7 9 15 9 22 0 19 -8 -15 -37 -29 -24 -39
                -64 -22 -89 13 -19 69 -25 83 -8 10 13 10 17 -2 25 -8 5 -21 9 -29 9 -13 0
                -13 -1 0 -10 13 -8 13 -10 -2 -10 -27 0 -43 38 -28 65 13 24 54 48 64 38 11
                -11 6 -21 -14 -26 -29 -7 -25 -24 10 -42 34 -17 38 -40 13 -67 -16 -17 -16
                -18 0 -12 10 3 23 30 33 65 9 32 21 59 27 59 6 0 24 -12 39 -27 l28 -27 0 37
                c0 23 5 37 13 37 8 0 11 -14 8 -46 -2 -32 0 -44 8 -40 7 5 11 -2 12 -16 0 -13
                3 -17 6 -10 2 6 17 13 31 13 21 1 23 2 7 6 -32 8 -45 23 -45 53 0 32 49 80 81
                80 26 0 44 -41 34 -77 -5 -15 -7 -32 -6 -36 1 -5 -2 -6 -7 -3 -4 2 -14 1 -21
                -4 -10 -6 -8 -10 10 -15 13 -4 27 -13 31 -19 4 -6 8 24 8 67 0 50 4 76 10 72
                6 -3 10 -25 10 -48 l1 -42 16 30 c20 38 36 48 46 29 5 -8 13 -11 19 -8 7 4 8
                2 4 -4 -4 -7 -3 -19 2 -29 7 -12 11 -4 17 35 4 29 11 55 17 58 13 8 15 5 22
                -36 5 -23 4 -30 -2 -21 -7 10 -12 2 -17 -28 -4 -23 -4 -45 -1 -48 3 -4 7 7 7
                23 2 27 2 27 8 -6 5 -31 10 -35 36 -36 28 -1 28 -1 3 3 -26 4 -41 33 -17 33 7
                0 8 11 4 31 -5 22 -4 29 4 25 6 -4 11 -1 11 8 0 8 3 22 7 31 5 14 2 16 -15 10
                -16 -5 -22 -2 -22 9 0 9 -7 16 -15 16 -20 0 -66 -41 -59 -52 4 -5 -4 -4 -16 4
                -18 11 -23 11 -36 -6 -13 -19 -14 -19 -14 6 0 24 -5 27 -51 33 -43 6 -56 4
                -79 -13 -17 -13 -36 -19 -49 -15 -14 3 -23 0 -27 -11 -4 -11 -18 -16 -45 -16
                -21 0 -39 -3 -39 -8 0 -9 -40 -35 -46 -29 -2 2 2 17 11 33 14 28 14 29 -8 36
                -20 5 -19 6 7 7 24 1 28 -2 23 -20 -5 -20 -5 -20 13 -4 11 10 26 15 35 12 11
                -5 15 0 15 18 0 30 -7 31 -33 8 -11 -10 -17 -12 -13 -6 5 10 -5 12 -46 8 -66
                -7 -237 -32 -275 -41 l-28 -6 20 20 c11 11 43 30 70 41 37 15 44 20 25 20 -14
                0 -38 -6 -55 -13z m37 -401 c-4 -32 -5 -33 -26 -21 -11 8 -34 17 -50 20 -44
                11 -11 32 54 35 24 1 26 -2 22 -34z m586 -917 c5 -7 10 -39 11 -71 0 -49 -2
                -58 -15 -53 -9 3 -24 6 -34 6 -11 0 -25 13 -34 30 -13 27 -13 30 2 30 12 0 13
                3 4 12 -9 9 -5 17 14 35 29 26 37 28 52 11z'/>
                <path className='draw-me' d='M1911 2676 c-8 -9 -8 -16 -2 -20 5 -4 13 -1 16 5 4 6 17 14 29 19 20
                8 20 9 -5 9 -15 1 -32 -5 -38 -13z'/>
                <path className='draw-me' d='M2380 2660 c-11 -11 -20 -29 -20 -40 0 -28 21 -70 36 -70 7 0 17 -8
                23 -17 5 -10 12 -15 16 -11 11 11 -5 48 -20 48 -37 0 -29 96 8 103 l22 4 -22
                2 c-13 0 -32 -8 -43 -19z'/>
                <path className='draw-me' d='M1785 2661 c-6 -5 -19 -11 -30 -14 -11 -2 -5 -3 13 -1 28 2 32 1 22
                -11 -6 -8 -24 -15 -38 -17 -26 -3 -26 -3 -4 -10 16 -4 31 0 50 18 15 13 21 24
                14 24 -7 0 -10 5 -7 10 8 13 -8 13 -20 1z'/>
                <path className='draw-me' d='M1002 2623 c-7 -10 -9 -11 -5 -3 6 13 5 13 -11 1 -26 -21 -17 -57 14
                -53 28 3 40 18 40 50 0 26 -20 29 -38 5z'/>
                <path className='draw-me' d='M2417 2620 c3 -10 11 -25 19 -31 8 -6 14 -19 15 -28 0 -9 7 -1 15 18
                17 39 13 49 -10 26 -16 -17 -20 -13 -17 18 1 9 -4 17 -12 17 -10 0 -13 -7 -10
                -20z'/>
                <path className='draw-me' d='M1980 2605 c0 -11 7 -15 23 -13 12 2 22 8 22 13 0 6 -10 11 -22 13
                -16 2 -23 -2 -23 -13z'/>
                <path className='draw-me' d='M1120 2553 l-25 -48 -55 2 c-41 1 -49 -1 -30 -7 21 -7 17 -8 -22 -9
                -27 -1 -48 3 -48 8 0 5 -7 12 -16 15 -8 3 -12 2 -9 -4 10 -16 -11 -12 -25 5
                -7 8 -9 15 -5 15 4 0 2 7 -5 15 -7 8 -17 12 -23 8 -5 -3 -7 -1 -3 5 3 6 2 13
                -3 16 -5 4 -12 -12 -16 -33 -8 -48 -25 -64 -66 -63 -16 0 -27 -4 -24 -9 4 -5
                -6 -9 -20 -9 -20 0 -26 4 -21 15 3 9 0 15 -9 15 -10 0 -15 -10 -15 -30 0 -33
                12 -37 57 -19 15 7 38 11 51 10 13 0 25 6 29 17 13 37 25 44 44 26 10 -9 27
                -14 39 -11 14 3 20 0 20 -10 0 -13 9 -14 53 -8 28 5 64 8 79 7 21 -1 33 7 51
                33 l23 34 11 -28 c10 -28 12 -28 83 -27 49 1 75 5 77 14 3 7 -5 12 -22 12 -14
                0 -36 5 -47 11 -12 6 -18 7 -14 1 8 -13 -17 -22 -34 -12 -7 4 -9 13 -5 20 5 8
                4 11 -1 7 -6 -3 -16 4 -24 16 -12 20 -12 21 3 8 20 -16 22 -2 5 22 -12 15 -16
                12 -38 -30z'/>
                <path className='draw-me' d='M2491 2578 c1 -17 2 -19 6 -5 2 9 8 17 13 17 5 0 7 -8 3 -17 -7 -19
                -5 -18 16 10 12 15 11 17 -13 17 -21 0 -26 -5 -25 -22z'/>
                <path className='draw-me' d='M1600 2565 c-11 -13 -8 -14 20 -9 22 5 35 3 40 -7 5 -7 12 -10 16 -6
                9 9 -26 36 -47 37 -9 0 -22 -7 -29 -15z'/>
                <path className='draw-me' d='M560 2561 c0 -6 4 -12 10 -16 5 -3 7 -12 3 -20 -3 -8 0 -15 6 -15 25
                0 9 -29 -19 -35 -18 -4 -30 -13 -30 -22 0 -13 3 -13 15 -3 8 7 15 9 15 5 0 -4
                7 -2 15 5 12 10 15 9 16 -6 0 -11 3 -14 6 -6 2 6 9 10 14 6 5 -3 9 -1 9 5 0 5
                -5 13 -10 16 -8 5 -7 11 1 21 9 11 8 14 -4 14 -9 0 -18 12 -22 30 -6 27 -25
                43 -25 21z'/>
                <path className='draw-me' d='M2528 2549 c-2 -8 -2 -25 2 -39 6 -25 6 -25 14 -3 8 25 -7 66 -16 42z'/>
                <path className='draw-me' d='M1890 2535 c-9 -11 -16 -13 -24 -5 -12 12 -26 13 -26 1 0 -20 45 -20
                65 -1 11 11 14 20 8 20 -6 0 -16 -7 -23 -15z'/>
                <path className='draw-me' d='M2584 2538 c3 -10 1 -18 -4 -18 -5 0 -7 -6 -4 -14 3 -8 1 -30 -4 -50
                -6 -20 -7 -50 -2 -71 7 -32 6 -36 -9 -32 -29 7 -28 -59 1 -83 l23 -19 -25 -1
                c-19 0 -25 -6 -26 -23 -1 -20 2 -23 22 -19 l23 4 -5 -113 c-3 -63 -9 -192 -14
                -287 -6 -95 -10 -206 -10 -246 0 -68 -1 -73 -21 -72 -12 1 -24 -5 -26 -11 -3
                -8 5 -13 19 -14 l23 0 -22 -11 c-17 -8 -23 -20 -23 -44 0 -18 -5 -34 -11 -36
                -7 -2 -10 -10 -8 -17 4 -11 8 -11 20 1 19 19 39 12 38 -15 0 -21 -1 -21 -8 -3
                -7 18 -8 18 -14 2 -10 -26 -8 -106 4 -149 7 -27 7 -48 0 -68 -6 -18 -6 -27 -1
                -24 6 4 10 -6 10 -21 0 -22 -3 -24 -14 -15 -12 10 -14 7 -10 -16 3 -15 5 -39
                4 -53 0 -14 -1 -37 -1 -52 0 -18 -5 -28 -14 -28 -17 0 -20 -16 -6 -24 5 -3 7
                -16 4 -29 -5 -22 -5 -22 11 -2 17 20 17 20 10 -5 -3 -14 -12 -32 -20 -40 -12
                -12 -14 -8 -14 30 -1 35 -3 41 -11 28 -6 -10 -16 -15 -26 -11 -11 4 -14 2 -11
                -8 3 -8 12 -13 20 -13 11 1 14 -5 10 -19 -3 -13 3 -26 19 -38 l24 -18 -30 2
                c-20 1 -29 -2 -27 -11 1 -8 -2 -11 -7 -8 -5 3 -11 -1 -15 -9 -3 -8 0 -15 6
                -15 6 0 6 -6 -3 -17 -7 -10 -2 -8 12 4 19 18 28 20 37 11 15 -15 16 -32 1 -23
                -5 4 -18 0 -28 -9 -28 -25 -51 -10 -51 32 0 34 -1 35 -14 16 -15 -21 -17 -75
                -3 -85 9 -6 -64 -68 -103 -86 -31 -15 -163 -53 -207 -60 -18 -3 -38 -12 -45
                -21 -12 -14 -5 -15 64 -9 42 4 107 17 144 29 59 19 76 31 143 100 60 62 80 78
                101 76 22 -3 25 1 25 27 1 20 12 41 33 62 18 17 32 41 32 53 l0 22 -21 -23
                -20 -24 5 85 c10 162 25 446 31 585 3 77 10 222 15 323 19 371 24 717 11 738
                -10 15 -11 16 -7 2z'/>
                <path className='draw-me' d='M2360 2501 c0 -35 8 -40 63 -44 25 -2 37 1 37 10 0 8 -13 13 -35 13
                -19 0 -36 5 -36 10 -1 6 -2 15 -3 22 0 6 -7 13 -13 16 -9 2 -13 -7 -13 -27z'/>
                <path className='draw-me' d='M2475 2494 c8 -43 23 -72 50 -96 44 -38 47 -15 5 32 -16 18 -33 48
                -36 67 -3 18 -11 33 -16 33 -6 0 -8 -14 -3 -36z'/>
                <path className='draw-me' d='M2151 2511 c-29 -5 -32 -8 -19 -19 12 -10 22 -10 44 -1 36 15 65 12
                80 -7 6 -8 17 -14 25 -14 8 1 3 11 -14 25 -28 25 -50 28 -116 16z'/>
                <path className='draw-me' d='M1830 2477 c0 -4 -47 -7 -105 -5 l-105 3 0 -49 c0 -42 2 -47 15 -36
                9 7 18 10 22 7 7 -8 5 -139 -3 -146 -3 -3 -14 0 -24 7 -19 11 -20 9 -20 -36 0
                -80 -29 -813 -34 -864 -4 -37 -2 -45 7 -37 7 7 11 28 9 50 -3 26 0 39 8 39 10
                0 9 4 -1 16 -19 23 2 33 57 26 30 -3 44 -1 44 7 0 8 -8 10 -20 6 -11 -4 -20
                -2 -20 4 0 6 -9 11 -20 11 -11 0 -21 7 -21 15 -1 8 -2 23 -3 33 -1 12 8 18 32
                20 41 4 42 21 2 20 -45 -1 -55 13 -39 59 12 36 13 67 7 178 -3 44 -1 50 17 51
                38 3 40 5 20 20 -17 12 -21 29 -24 106 -2 68 0 93 10 99 10 6 11 9 2 9 -15 0
                -17 46 -3 55 6 3 7 17 4 30 -5 20 -2 24 23 28 28 4 28 4 -4 6 -37 1 -52 17
                -25 25 10 3 76 7 148 9 l129 2 -3 30 c-2 17 3 51 11 78 15 45 14 47 -5 47 -17
                0 -20 -5 -15 -30 9 -40 0 -59 -24 -53 -23 6 -25 -6 -2 -22 14 -10 7 -13 -43
                -20 -113 -14 -122 -14 -135 2 -11 13 -10 18 10 34 l23 19 -25 0 c-31 0 -41 14
                -28 37 8 14 4 23 -17 40 -26 22 -26 24 -9 37 14 11 51 14 158 13 88 -1 138 2
                136 8 -2 5 -20 8 -40 7 -20 -2 -37 0 -37 5 0 5 -9 9 -20 9 -11 0 -20 -4 -20
                -9z'/>
                <path className='draw-me' d='M1098 2453 c-3 -26 -7 -28 -57 -33 -46 -5 -55 -3 -59 12 -5 20 -17
                23 -27 7 -4 -6 -23 -9 -42 -7 -21 1 -37 -3 -43 -11 -4 -8 -24 -16 -43 -18 -29
                -3 -36 0 -41 19 -5 19 -8 20 -15 8 -5 -8 -7 -18 -5 -22 3 -4 0 -8 -6 -8 -6 0
                -9 -4 -5 -9 3 -5 1 -13 -5 -16 -6 -4 -9 -1 -8 6 3 13 -11 14 -102 10 -27 -1
                -30 -4 -30 -32 l0 -32 47 7 c26 3 98 9 160 13 l113 6 0 32 c0 24 5 34 20 38
                17 4 20 0 20 -29 l0 -35 53 2 c81 4 87 7 87 45 0 30 3 34 25 34 22 0 25 -4 25
                -35 l0 -36 103 7 c56 3 116 7 132 9 17 2 60 4 98 4 l67 1 0 40 c0 27 -4 38
                -12 35 -12 -3 -75 -9 -223 -21 -33 -2 -70 -7 -83 -9 -14 -4 -24 0 -28 9 -4 10
                -16 12 -43 9 -26 -4 -42 0 -54 11 -14 15 -16 14 -19 -11z m157 -43 c-11 -4
                -27 -6 -35 -4 -8 1 -21 3 -28 3 -7 1 -11 5 -8 9 2 4 24 6 48 3 32 -3 38 -6 23
                -11z m122 9 c-12 -7 -27 -7 -45 0 -25 9 -24 9 18 10 36 0 41 -2 27 -10z'/>
                <path className='draw-me' d='M2167 2461 c5 -21 18 -25 113 -37 72 -9 182 -46 188 -63 4 -11 -1
                -12 -19 -7 -49 13 -181 37 -186 33 -2 -3 29 -12 69 -22 126 -29 172 -53 176
                -93 3 -28 1 -29 -50 -35 -77 -10 -128 -8 -128 3 0 6 -9 10 -20 10 -11 0 -20
                -4 -20 -10 0 -5 9 -10 19 -10 11 0 23 -5 27 -12 5 -8 -4 -9 -33 -5 -48 8 -66
                -7 -66 -56 0 -17 -6 -158 -13 -311 -16 -331 -16 -296 -6 -296 5 0 12 73 16
                163 3 89 9 230 13 313 l6 151 97 7 c53 3 111 9 128 12 l32 6 -5 -93 c-3 -52
                -10 -206 -16 -344 -6 -137 -12 -251 -14 -253 -1 -1 -33 2 -71 7 -159 23 -184
                24 -184 7 0 -12 11 -17 43 -18 23 -1 56 -3 72 -3 17 -1 29 -7 28 -13 -2 -7 21
                -10 62 -10 39 1 65 6 66 12 0 6 5 65 10 131 5 66 10 183 11 260 l1 140 7 -95
                c4 -54 5 -18 2 85 -3 99 -4 217 -3 262 2 72 1 82 -13 77 -12 -5 -15 -2 -10 10
                4 11 0 16 -13 16 -10 0 -23 4 -28 9 -13 13 -136 51 -146 46 -4 -3 -12 1 -18 9
                -8 10 -24 12 -52 9 -29 -4 -44 -1 -59 12 -18 16 -18 16 -13 -4z'/>
                <path className='draw-me' d='M2010 2389 c0 -5 5 -7 10 -4 19 12 16 -65 -6 -139 -23 -80 -14 -87
                11 -11 l24 70 -2 -60 c-1 -33 -5 -62 -8 -65 -7 -6 -49 -94 -49 -103 0 -11 35
                27 46 49 6 14 10 3 12 -44 3 -63 22 -80 22 -19 0 18 4 39 10 47 5 9 5 19 -2
                27 -17 21 -2 41 34 46 29 3 28 4 -9 5 -35 2 -43 5 -43 21 0 11 4 22 9 25 5 4
                5 21 -1 43 -22 90 -21 91 70 87 42 -3 82 -6 89 -9 7 -2 14 -29 16 -62 3 -55 3
                -54 4 12 l0 71 -66 2 c-36 2 -84 7 -106 12 -53 12 -65 12 -65 -1z'/>
                <path className='draw-me' d='M1952 2365 c0 -16 4 -41 8 -55 7 -23 8 -22 8 10 0 19 -3 44 -8 55 -7
                17 -9 15 -8 -10z'/>
                <path className='draw-me' d='M1117 2363 c-11 -10 -8 -41 5 -63 10 -15 10 -22 0 -32 -6 -6 -12 -25
                -12 -41 l0 -28 -70 2 c-111 4 -108 -17 3 -23 31 -2 57 -6 57 -10 0 -5 0 -71 0
                -147 l0 -139 -77 5 c-56 5 -74 3 -69 -5 4 -7 34 -12 72 -12 l66 0 -3 -122 -3
                -123 -71 -5 c-88 -6 -86 -26 3 -22 l62 3 0 -41 c0 -38 -1 -40 -32 -41 -28 -1
                -25 -3 16 -11 27 -5 50 -8 51 -6 6 5 32 842 27 856 -5 13 -15 16 -25 5z'/>
                <path className='draw-me' d='M1557 2363 c-4 -3 -6 -61 -5 -127 l1 -121 7 125 c4 69 6 126 5 128
                -1 1 -5 -1 -8 -5z'/>
                <path className='draw-me' d='M931 2327 c-9 -24 -31 -512 -31 -695 0 -102 2 -113 17 -110 15 3 18
                17 19 88 3 126 14 466 20 613 4 88 2 127 -6 127 -6 0 -14 -10 -19 -23z'/>
                <path className='draw-me' d='M764 2294 c-4 -14 -2 -30 5 -37 8 -8 11 -1 11 26 0 43 -7 48 -16 11z'/>
                <path className='draw-me' d='M1030 2291 c0 -5 8 -15 17 -22 14 -12 15 -12 9 0 -7 10 -2 12 23 6
                24 -5 31 -3 31 9 0 12 -10 16 -40 16 -22 0 -40 -4 -40 -9z'/>
                <path className='draw-me' d='M1260 2260 l-85 -7 106 0 c58 0 114 4 125 8 22 9 -19 9 -146 -1z'/>
                <path className='draw-me' d='M1458 2263 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z'/>
                <path className='draw-me' d='M2170 2263 c1 -19 16 -31 29 -24 7 5 10 14 7 20 -8 12 -36 15 -36 4z'/>
                <path className='draw-me' d='M447 2193 c-10 -100 -8 -251 2 -233 12 20 22 300 12 300 -5 0 -11
                -30 -14 -67z'/>
                <path className='draw-me' d='M625 2230 c-7 -12 137 -12 230 0 48 6 32 7 -82 9 -85 0 -144 -3 -148
                -9z'/>
                <path className='draw-me' d='M1921 2200 c0 -19 4 -42 9 -50 7 -11 10 -8 9 15 0 17 -4 39 -9 50 -7
                16 -9 14 -9 -15z'/>
                <path className='draw-me' d='M1884 2210 c-12 -12 -6 -70 7 -70 5 0 9 18 9 40 0 43 -1 45 -16 30z'/>
                <path className='draw-me' d='M753 2198 c-2 -5 -33 -8 -68 -8 l-63 0 -6 -132 c-3 -73 -7 -203 -10
                -288 -3 -129 -2 -141 5 -70 5 47 11 174 15 283 l6 197 44 0 44 0 1 -137 c1
                -103 4 -127 10 -93 4 25 8 87 8 138 l1 92 68 1 c102 1 123 16 29 20 -44 2 -81
                1 -84 -3z'/>
                <path className='draw-me' d='M1733 2203 c20 -2 54 -2 75 0 20 2 3 4 -38 4 -41 0 -58 -2 -37 -4z'/>
                <path className='draw-me' d='M1807 2183 c-15 -14 -6 -33 18 -39 17 -4 25 -13 25 -28 0 -17 -2 -18
                -10 -6 -8 12 -10 10 -11 -10 -1 -24 -1 -81 1 -125 2 -40 -11 -310 -16 -324 -2
                -7 0 -19 5 -25 5 -6 6 -33 1 -59 -4 -27 -6 -60 -4 -74 2 -19 -4 -28 -29 -38
                l-32 -14 38 -1 c20 0 37 3 37 8 12 173 30 576 30 655 0 49 -3 66 -12 61 -6 -4
                -16 0 -23 9 -7 9 -15 14 -18 10z'/>
                <path className='draw-me' d='M2175 2173 c17 -38 17 -142 1 -211 -18 -78 -22 -298 -7 -304 12 -4
                -7 -98 -20 -98 -5 0 -9 -11 -9 -25 0 -14 4 -25 9 -25 5 0 17 -3 26 -7 15 -5
                16 -1 10 25 -3 17 -2 81 4 144 6 62 13 204 17 316 l7 202 -23 0 c-17 0 -21 -4
                -15 -17z'/>
                <path className='draw-me' d='M2533 2130 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z'/>
                <path className='draw-me' d='M2132 2070 c-1 -30 -4 -100 -7 -155 -4 -80 -3 -92 5 -60 6 22 9 91 7
                155 -1 63 -4 90 -5 60z'/>
                <path className='draw-me' d='M1176 2101 c-9 -13 -18 -12 125 -11 114 1 117 0 121 -22 l4 -23 -128
                3 c-87 3 -128 0 -128 -7 0 -7 42 -11 125 -11 l125 0 1 -25 c2 -68 1 -91 -6
                -108 -4 -11 -3 -23 4 -30 12 -12 12 -15 15 92 2 49 7 67 21 77 17 13 17 14 2
                14 -10 0 -17 8 -17 20 0 13 7 20 20 20 13 0 20 -7 20 -20 0 -15 7 -20 25 -20
                20 0 25 5 25 25 0 25 -2 25 -77 25 -43 0 -121 3 -174 6 -67 5 -98 3 -103 -5z'/>
                <path className='draw-me' d='M1890 2099 c0 -24 16 -41 35 -37 14 2 13 7 -9 27 -18 17 -26 20 -26
                10z'/>
                <path className='draw-me' d='M1962 2070 c-1 -14 3 -43 7 -65 l9 -40 0 40 c0 22 -4 51 -8 65 l-8
                25 0 -25z'/>
                <path className='draw-me' d='M2004 2008 c3 -18 15 -40 25 -49 21 -18 22 -2 -8 -200 -13 -84 -21
                -85 -22 -3 -1 38 -5 80 -10 94 -6 20 -7 17 -4 -15 8 -75 10 -200 2 -221 -7
                -19 -4 -20 25 -15 21 3 26 6 15 9 -13 2 -16 9 -12 20 15 40 45 213 49 282 1
                14 4 30 7 35 3 6 0 21 -7 34 l-12 23 -7 -23 -7 -22 -13 24 c-9 15 -10 28 -4
                35 6 8 4 14 -7 18 -14 5 -16 1 -10 -26z'/>
                <path className='draw-me' d='M1480 2005 c0 -29 34 -36 44 -9 10 27 7 34 -19 34 -20 0 -25 -5 -25
                -25z'/>
                <path className='draw-me' d='M1911 1964 c-12 -19 -21 -47 -21 -62 1 -25 2 -24 19 13 38 82 39 113
                2 49z'/>
                <path className='draw-me' d='M1176 1982 c-4 -7 31 -12 105 -14 61 -2 113 0 116 5 2 4 -29 7 -69 7
                -40 0 -89 3 -109 6 -21 4 -39 2 -43 -4z'/>
                <path className='draw-me' d='M1475 1946 c1 -11 9 -22 18 -24 9 -2 17 -8 17 -14 0 -6 -9 -8 -20 -5
                -16 4 -20 0 -20 -18 0 -15 7 -25 21 -28 12 -3 18 -10 14 -16 -3 -6 -1 -11 4
                -11 7 0 11 27 11 68 0 63 -1 67 -23 68 -18 1 -23 -3 -22 -20z'/>
                <path className='draw-me' d='M1994 1918 c9 -84 28 -120 22 -41 -2 33 -9 69 -16 80 -9 14 -10 7 -6
                -39z'/>
                <path className='draw-me' d='M1170 1921 c0 -13 -10 -12 122 -14 131 -2 120 8 -19 18 -73 5 -103 4
                -103 -4z'/>
                <path className='draw-me' d='M638 1898 c-9 -5 -7 -8 7 -8 11 0 30 -3 43 -6 12 -4 22 -2 22 4 0 13
                -56 21 -72 10z'/>
                <path className='draw-me' d='M770 1890 c0 -6 28 -9 68 -9 66 1 66 2 22 10 -63 11 -90 11 -90 -1z'/>
                <path className='draw-me' d='M734 1879 c-20 -7 -22 -18 -29 -129 -5 -82 -4 -125 3 -132 16 -16 22
                19 25 137 l2 100 60 -1 c34 -1 43 1 20 3 -22 2 -45 10 -50 17 -7 9 -18 10 -31
                5z'/>
                <path className='draw-me' d='M1170 1860 c0 -6 31 -10 74 -10 40 0 95 -2 122 -5 43 -4 44 -4 14 4
                -58 14 -210 22 -210 11z'/>
                <path className='draw-me' d='M1880 1744 c0 -96 3 -123 14 -127 16 -6 26 13 26 49 0 14 -4 22 -10
                19 -6 -4 -9 21 -8 68 0 40 -4 82 -10 93 -9 15 -11 -8 -12 -102z'/>
                <path className='draw-me' d='M438 1837 c-5 -10 -8 -69 -7 -130 0 -83 3 -106 10 -88 5 14 8 72 7
                130 -2 60 -6 98 -10 88z'/>
                <path className='draw-me' d='M1418 1823 c-3 -26 -5 -27 -58 -25 -30 1 -88 5 -129 8 -54 5 -72 4
                -67 -5 4 -7 41 -11 94 -12 79 -1 171 -15 117 -18 -17 -1 -17 -3 -5 -11 11 -7
                19 -5 27 7 11 14 13 14 22 -8 l10 -24 0 24 c1 19 5 22 24 17 12 -3 28 -6 35
                -6 6 0 12 -11 12 -24 0 -23 -2 -24 -62 -19 -35 2 -96 9 -136 14 -92 11 -142
                12 -142 0 0 -5 56 -12 125 -16 119 -7 125 -8 126 -29 0 -20 1 -20 8 -2 10 25
                41 30 41 6 0 -14 38 -36 46 -27 1 1 4 32 7 70 5 66 5 67 -19 67 -13 0 -24 -4
                -24 -10 0 -5 -9 -10 -20 -10 -26 0 -28 46 -2 53 13 4 12 5 -5 6 -16 1 -22 -5
                -25 -26z'/>
                <path className='draw-me' d='M1965 1752 c-6 -15 -8 -56 -5 -92 l5 -65 6 90 c4 50 6 91 5 92 -1 1
                -6 -10 -11 -25z'/>
                <path className='draw-me' d='M1534 1650 c0 -69 1 -97 3 -62 2 34 2 90 0 125 -2 34 -3 6 -3 -63z'/>
                <path className='draw-me' d='M1160 1681 c0 -7 50 -13 234 -25 5 -1 12 -10 17 -21 6 -17 8 -17 8
                -2 1 23 41 23 41 -1 0 -12 8 -18 25 -20 20 -1 25 3 25 21 0 15 -6 23 -17 23
                -18 1 -76 7 -208 24 -90 12 -125 12 -125 1z'/>
                <path className='draw-me' d='M2074 1635 c-8 -20 22 -44 55 -45 30 0 20 12 -11 14 -15 1 -22 7 -20
                18 4 22 -17 33 -24 13z'/>
                <path className='draw-me' d='M1163 1623 c4 -6 49 -14 100 -18 128 -10 223 -28 220 -43 -2 -7 4
                -12 12 -12 9 0 15 9 15 23 0 17 -6 22 -27 23 -16 0 -71 6 -123 12 -52 7 -119
                15 -149 19 -37 4 -52 3 -48 -4z'/>
                <path className='draw-me' d='M839 1615 c-4 -3 -51 -6 -105 -6 -55 0 -106 -4 -114 -9 -17 -11 -20
                -11 48 -13 28 -1 52 0 52 3 0 3 35 5 77 5 43 0 80 5 83 10 5 11 -32 20 -41 10z'/>
                <path className='draw-me' d='M1876 1543 c-10 -86 -10 -87 2 -91 15 -5 19 19 12 93 l-6 70 -8 -72z'/>
                <path className='draw-me' d='M1174 1592 c3 -5 42 -9 88 -9 75 -1 78 0 33 7 -67 11 -127 12 -121 2z'/>
                <path className='draw-me' d='M1167 1559 c16 -20 67 -35 166 -49 83 -12 93 -11 83 15 -4 13 -18 16
                -58 15 -29 -2 -60 0 -68 3 -8 3 -33 8 -55 10 -22 3 -49 9 -60 13 -17 7 -18 5
                -8 -7z'/>
                <path className='draw-me' d='M2097 1530 c-19 -18 -87 -33 -87 -18 0 4 -6 8 -14 8 -9 0 -12 -7 -9
                -18 4 -15 -4 -21 -39 -29 -33 -8 -37 -11 -16 -12 15 0 37 3 50 8 13 5 49 15
                81 22 50 11 57 15 57 36 0 27 0 27 -23 3z'/>
                <path className='draw-me' d='M1447 1510 c3 -11 12 -20 19 -20 8 0 18 -8 21 -17 5 -14 8 -11 14 12
                10 36 4 45 -31 45 -23 0 -27 -3 -23 -20z'/>
                <path className='draw-me' d='M1165 1510 c-6 -10 97 -25 117 -17 7 3 -1 6 -17 6 -17 1 -45 5 -62
                10 -18 5 -35 6 -38 1z'/>
                <path className='draw-me' d='M2442 1430 c-12 -5 -53 -12 -90 -15 -37 -3 -91 -8 -121 -10 -30 -3
                -57 -8 -60 -11 -7 -8 220 3 243 12 21 8 21 -4 -1 -28 -15 -17 -15 -18 18 -12
                42 8 38 5 49 43 9 34 3 37 -38 21z'/>
                <path className='draw-me' d='M937 1351 c-12 -27 -27 -43 -49 -50 -37 -13 -38 -13 -37 -3 3 18 -2
                32 -12 32 -6 0 -9 -12 -7 -27 3 -24 7 -28 32 -25 16 2 36 -4 47 -14 10 -9 19
                -13 20 -8 4 42 9 55 32 86 24 31 25 37 11 42 -8 3 -17 6 -18 6 -1 0 -10 -18
                -19 -39z'/>
                <path className='draw-me' d='M670 1355 c0 -7 -9 -17 -21 -20 -11 -4 -19 -9 -16 -11 6 -6 67 17 67
                26 0 4 -7 10 -15 14 -9 3 -15 0 -15 -9z'/>
                <path className='draw-me' d='M1913 1348 c-5 -9 65 -13 141 -9 27 2 60 -3 74 -10 20 -11 26 -11 29
                -1 3 6 11 12 18 12 8 0 16 4 20 9 3 6 -6 7 -22 3 -16 -4 -79 -5 -141 -2 -63 3
                -116 2 -119 -2z'/>
                <path className='draw-me' d='M2357 1338 c-15 -21 -16 -22 -16 -3 -1 14 -4 16 -12 8 -14 -14 -3
                -33 19 -33 15 0 15 -2 2 -10 -12 -8 -9 -10 13 -10 20 0 27 5 29 20 0 11 1 25
                2 32 2 21 -21 19 -37 -4z'/>
                <path className='draw-me' d='M2230 1320 c-23 -18 -24 -20 -4 -20 11 0 25 5 30 10 14 14 41 3 47
                -20 5 -21 -21 -50 -46 -50 -9 0 -21 9 -27 20 -16 29 -30 24 -30 -10 0 -21 5
                -30 18 -31 25 -2 25 -2 51 6 21 6 23 5 17 -11 -6 -15 -5 -17 5 -6 7 6 20 12
                29 12 12 -1 10 -5 -6 -18 -11 -10 -21 -25 -21 -35 0 -14 6 -12 28 11 29 27 37
                52 19 52 -5 0 -10 5 -10 11 0 6 9 9 20 6 13 -3 20 0 20 9 0 8 -6 14 -13 14 -7
                0 -21 16 -31 35 -22 41 -56 46 -96 15z'/>
                <path className='draw-me' d='M2442 1260 c1 -44 7 -81 13 -83 5 -3 10 5 11 17 0 11 3 48 7 81 4 38
                3 51 -2 35 l-9 -25 -1 28 c-1 15 -5 27 -11 27 -6 0 -9 -33 -8 -80z'/>
                <path className='draw-me' d='M1886 1306 c6 -24 3 -26 -25 -26 -35 0 -37 3 -15 24 13 14 13 16 0
                16 -17 0 -86 -62 -86 -79 0 -6 3 -10 8 -9 4 0 15 -2 24 -6 9 -4 25 -6 35 -3
                19 4 17 6 -31 21 -15 5 -16 10 -8 18 8 8 24 9 57 1 44 -10 47 -9 55 13 5 13
                17 24 25 24 9 0 13 5 10 11 -4 6 -9 8 -12 5 -3 -3 -14 -1 -25 5 -17 9 -18 7
                -12 -15z'/>
                <path className='draw-me' d='M715 1309 c-11 -5 -33 -14 -50 -19 -22 -8 -26 -13 -16 -20 12 -7 44
                3 61 20 10 10 30 10 30 0 0 -5 5 -10 10 -10 14 0 12 27 -2 33 -7 2 -22 0 -33
                -4z'/>
                <path className='draw-me' d='M2105 1290 c-4 -6 -18 -9 -35 -5 -25 6 -27 4 -23 -19 3 -16 1 -26 -6
                -26 -6 0 -11 -4 -11 -8 0 -11 100 -41 105 -32 3 4 15 6 28 5 22 -3 23 0 18 38
                -4 22 -11 42 -18 44 -9 3 -10 -6 -6 -33 5 -29 3 -36 -8 -32 -8 2 -15 18 -17
                34 -4 34 -17 51 -27 34z'/>
                <path className='draw-me' d='M2408 1287 c-5 -10 -8 -29 -8 -42 0 -12 -6 -34 -13 -48 -11 -20 -17
                -23 -25 -15 -15 15 -15 3 0 -21 11 -17 14 -15 35 26 16 29 23 57 21 81 -2 28
                -5 32 -10 19z'/>
                <path className='draw-me' d='M1508 1273 c-21 -8 -38 -19 -38 -24 0 -13 6 -11 50 11 62 32 53 41
                -12 13z'/>
                <path className='draw-me' d='M1373 1268 c-29 -14 -29 -22 1 -41 24 -16 36 -13 36 7 0 6 -5 4 -11
                -4 -9 -12 -10 -12 -5 3 3 10 6 21 6 23 0 3 5 2 10 -1 6 -3 10 1 10 9 0 18 -15
                19 -47 4z'/>
                <path className='draw-me' d='M1956 1255 c-3 -8 -16 -15 -30 -15 -21 0 -24 -5 -24 -34 0 -19 -3
                -39 -7 -45 -3 -6 -2 -11 3 -11 4 0 14 16 22 35 14 35 30 42 38 14 3 -11 2 -11
                -7 1 -9 12 -11 11 -11 -7 0 -13 -5 -23 -12 -23 -6 0 -9 -3 -6 -6 4 -3 25 7 49
                22 38 24 41 28 26 39 -9 7 -17 20 -17 29 0 20 -16 21 -24 1z'/>
                <path className='draw-me' d='M757 1239 c-22 -11 -41 -18 -45 -15 -8 9 -33 -15 -26 -25 9 -15 33
                -10 74 16 49 31 54 31 69 3 11 -23 11 -23 5 2 -6 23 -21 40 -34 39 -3 0 -22
                -9 -43 -20z'/>
                <path className='draw-me' d='M1273 1233 c-19 -7 -16 -21 7 -33 18 -9 20 -8 20 15 0 25 -4 28 -27
                18z'/>
                <path className='draw-me' d='M926 1219 c2 -4 28 -9 59 -11 30 -3 56 -7 58 -9 2 -2 1 -42 -1 -88
                l-4 -83 -34 5 c-20 4 -32 2 -29 -4 4 -5 22 -9 41 -9 31 0 34 2 34 31 0 17 4
                28 10 24 6 -3 10 -1 10 4 0 6 -4 11 -10 11 -13 0 -13 45 -1 87 9 31 8 33 -22
                37 -56 9 -115 12 -111 5z'/>
                <path className='draw-me' d='M1310 1222 c0 -15 69 -76 90 -79 32 -5 23 14 -14 32 -18 9 -43 25
                -55 36 -11 11 -21 16 -21 11z'/>
                <path className='draw-me' d='M499 1184 c-10 -29 -3 -47 10 -25 11 17 31 6 31 -17 0 -12 3 -13 11
                -5 7 7 8 24 4 42 -9 40 -41 42 -56 5z'/>
                <path className='draw-me' d='M1849 1208 c0 -2 -2 -21 -4 -42 -4 -36 -3 -37 10 -20 17 22 20 64 5
                64 -5 0 -10 -1 -11 -2z'/>
                <path className='draw-me' d='M365 1175 c-3 -8 -7 -21 -9 -29 -3 -10 5 -17 25 -21 28 -6 29 -9 30
                -68 3 -141 7 -157 35 -157 8 0 28 -7 44 -15 25 -13 211 -65 233 -65 5 0 6 4 3
                9 -3 4 6 13 19 19 15 7 25 8 25 2 0 -13 47 -13 55 0 4 6 10 8 15 5 4 -3 10 2
                13 10 4 10 1 12 -11 8 -9 -4 -20 -11 -24 -17 -5 -8 -13 -8 -25 0 -14 9 -18 25
                -18 68 0 31 -3 56 -7 56 -14 0 -22 -25 -19 -67 2 -40 1 -43 -23 -43 -14 0 -26
                1 -26 3 0 1 4 17 9 34 6 20 6 35 0 38 -5 4 -9 2 -9 -3 0 -5 -9 -12 -20 -15
                -13 -3 -18 -10 -14 -21 7 -19 5 -19 -71 -1 -73 18 -98 19 -72 4 9 -5 17 -14
                17 -19 0 -11 -62 10 -72 24 -14 21 -8 48 12 53 19 5 19 6 -5 24 -20 16 -24 28
                -23 69 0 28 4 50 10 50 5 0 3 7 -6 15 -8 8 -21 12 -29 9 -10 -3 -18 5 -25 25
                -12 33 -30 41 -37 16z m333 -312 c6 -6 12 -15 12 -18 0 -8 -69 3 -85 13 -5 4
                -16 7 -23 8 -8 2 -16 5 -20 8 -11 12 103 1 116 -11z'/>
                <path className='draw-me' d='M817 1183 c-8 -8 -9 -53 -1 -53 3 0 14 5 25 10 16 9 17 14 6 30 -13
                21 -20 24 -30 13z'/>
                <path className='draw-me' d='M989 1188 c-8 -18 -5 -57 5 -61 25 -10 37 8 27 36 -9 22 -27 36 -32
                25z'/>
                <path className='draw-me' d='M2030 1182 c0 -5 14 -12 30 -15 18 -4 30 -13 30 -22 0 -9 5 -13 10
                -10 6 4 8 11 5 16 -4 5 -1 9 4 9 6 0 11 4 11 8 0 5 -6 9 -12 9 -7 0 -28 3 -45
                7 -20 5 -33 4 -33 -2z'/>
                <path className='draw-me' d='M1161 1162 c-7 -13 -4 -20 13 -28 20 -9 22 -8 18 18 -5 32 -17 36
                -31 10z'/>
                <path className='draw-me' d='M1977 1162 c-25 -27 -21 -35 8 -16 25 16 33 34 17 34 -5 0 -16 -8
                -25 -18z'/>
                <path className='draw-me' d='M1114 1153 c-4 -10 -6 -21 -6 -26 1 -4 -10 -4 -25 -1 -16 4 -24 3
                -20 -2 5 -5 12 -9 15 -9 4 -1 16 -6 27 -12 18 -10 20 -8 22 28 1 43 -1 47 -13
                22z'/>
                <path className='draw-me' d='M1220 1160 c0 -5 3 -10 8 -10 4 0 24 -16 46 -35 22 -20 49 -35 63
                -35 22 0 24 2 13 15 -7 8 -17 15 -22 15 -6 0 -27 14 -48 30 -39 31 -60 38 -60
                20z'/>
                <path className='draw-me' d='M910 1146 c0 -14 67 -43 76 -33 3 3 -9 10 -26 16 -17 6 -29 15 -25
                21 3 5 -1 10 -9 10 -9 0 -16 -6 -16 -14z'/>
                <path className='draw-me' d='M1540 1150 c-8 -5 -11 -10 -7 -10 4 0 3 -9 -3 -20 -7 -14 -7 -20 0
                -20 5 0 10 7 10 15 0 8 8 15 18 15 13 0 14 2 3 9 -11 7 -11 9 0 14 8 3 10 6 4
                6 -5 0 -17 -4 -25 -9z'/>
                <path className='draw-me' d='M1780 1154 c0 -5 -14 -18 -31 -28 -29 -17 -31 -17 -43 0 -12 16 -13
                15 -19 -5 -8 -31 17 -38 58 -15 33 18 51 41 40 52 -3 3 -5 1 -5 -4z'/>
                <path className='draw-me' d='M2327 1128 c-29 -32 -30 -42 -5 -33 14 6 43 54 36 60 -2 2 -15 -11
                -31 -27z'/>
                <path className='draw-me' d='M2480 1150 c-1 -8 -2 -19 -1 -23 1 -4 -6 -10 -14 -13 -17 -7 -21 -34
                -5 -34 6 0 10 6 10 14 0 8 5 16 11 18 6 2 9 15 6 28 -2 14 -5 18 -7 10z'/>
                <path className='draw-me' d='M2030 1128 c0 -14 5 -18 15 -14 21 8 20 -52 -2 -76 -9 -10 -13 -21
                -9 -26 13 -12 36 30 36 64 0 18 3 39 6 47 3 10 -3 16 -20 19 -21 4 -26 1 -26
                -14z'/>
                <path className='draw-me' d='M1980 1120 c-23 -17 -23 -19 -6 -26 18 -7 36 9 36 34 0 15 0 15 -30
                -8z'/>
                <path className='draw-me' d='M684 1085 c6 -22 4 -22 -28 -15 -40 9 -53 -6 -32 -36 7 -11 13 -26
                13 -35 0 -13 -7 -15 -34 -8 -19 4 -32 5 -29 2 8 -9 -22 -34 -42 -36 -10 0 -19
                -7 -19 -14 0 -18 5 -17 57 13 41 23 45 24 57 8 8 -11 20 -15 37 -10 34 8 33
                27 -4 59 -35 32 -38 47 -10 47 19 0 32 -11 64 -55 14 -19 16 -19 32 -3 17 17
                11 43 -8 32 -6 -4 -8 -2 -5 3 4 6 -2 18 -13 28 -14 12 -17 21 -9 25 6 5 2 9
                -11 13 -19 5 -21 2 -16 -18z'/>
                <path className='draw-me' d='M1563 1096 c-13 -6 -23 -14 -23 -19 0 -4 5 -5 10 -2 6 4 18 1 27 -7
                14 -11 16 -11 8 1 -6 11 -1 12 29 7 29 -6 36 -4 36 9 0 13 -7 15 -37 10 -28
                -5 -34 -4 -23 4 19 14 2 12 -27 -3z'/>
                <path className='draw-me' d='M495 1070 c3 -5 15 -10 26 -10 12 0 19 -7 19 -19 0 -31 15 -32 17 -1
                2 21 -3 29 -20 34 -33 9 -49 7 -42 -4z'/>
                <path className='draw-me' d='M2083 1065 c-3 -10 -1 -26 5 -36 16 -25 15 -29 -5 -30 -15 0 -15 -2
                2 -9 13 -5 26 -3 38 8 20 18 23 40 3 23 -12 -10 -14 -6 -13 20 2 35 -19 51
                -30 24z'/>
                <path className='draw-me' d='M2330 1057 c0 -9 7 -22 15 -31 8 -8 15 -17 15 -21 0 -10 -23 -30 -41
                -37 -20 -8 -14 -52 10 -70 13 -10 14 -10 4 2 -9 9 -9 18 -1 33 8 16 12 17 19
                6 4 -7 11 -10 15 -6 4 4 2 13 -4 19 -17 17 -15 28 4 28 10 0 14 6 10 15 -3 8
                3 19 12 25 15 10 14 11 -5 7 -15 -3 -23 0 -23 8 0 7 -7 18 -15 25 -12 10 -15
                10 -15 -3z'/>
                <path className='draw-me' d='M2238 1046 c-10 -10 -18 -24 -18 -33 0 -10 -6 -13 -16 -9 -12 4 -15
                2 -11 -9 4 -8 2 -17 -3 -20 -5 -3 -11 3 -14 12 -3 10 -4 6 -2 -10 2 -15 -1
                -30 -5 -33 -12 -7 -11 -34 1 -34 6 0 10 7 10 15 0 9 10 15 23 16 12 0 16 3 10
                6 -7 2 -13 9 -13 15 0 6 7 8 15 4 8 -3 15 -1 15 4 0 6 -6 10 -12 10 -7 0 0 10
                16 23 17 14 27 30 25 42 -4 18 -5 18 -21 1z'/>
                <path className='draw-me' d='M470 1038 c1 -7 16 -20 36 -29 44 -21 40 -2 -4 24 -24 14 -32 16 -32
                5z'/>
                <path className='draw-me' d='M2458 1025 c-4 -11 -9 -36 -12 -55 l-6 -35 15 33 c8 17 22 32 30 32
                8 0 15 7 15 16 0 13 -3 14 -14 5 -11 -10 -15 -8 -18 6 -4 16 -5 16 -10 -2z'/>
                <path className='draw-me' d='M809 1011 c-20 -16 -22 -23 -14 -47 7 -20 18 -30 35 -32 l25 -2 -22
                16 c-25 18 -31 64 -9 64 9 0 13 -8 10 -22 l-5 -23 12 23 c18 34 -1 48 -32 23z'/>
                <path className='draw-me' d='M946 1002 c-3 -5 -21 -8 -40 -6 -42 3 -63 -16 -47 -43 10 -17 10 -17
                11 3 0 32 11 35 31 11 l18 -22 1 25 c1 22 2 23 9 6 7 -19 8 -19 13 2 7 24 37
                29 56 10 6 -6 15 -9 19 -5 9 10 1 16 -34 23 -17 3 -33 2 -37 -4z'/>
                <path className='draw-me' d='M1076 985 c-15 -8 -35 -15 -42 -15 -8 0 -14 -10 -14 -22 0 -21 0 -21
                14 -2 14 18 15 18 21 2 11 -28 28 -21 22 8 -5 26 -4 26 42 21 25 -2 55 -1 66
                4 16 8 15 8 -7 4 -15 -3 -30 -1 -33 5 -9 14 -35 12 -69 -5z'/>
                <path className='draw-me' d='M2130 990 c0 -5 -15 -14 -32 -19 -26 -7 -28 -9 -10 -10 17 -1 22 -7
                22 -26 0 -26 14 -33 25 -15 4 6 2 17 -4 24 -8 10 -7 17 4 26 17 14 20 30 5 30
                -5 0 -10 -4 -10 -10z'/>
                <path className='draw-me' d='M954 969 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
                -11z'/>
                <path className='draw-me' d='M1096 963 c-9 -10 -7 -63 3 -63 11 0 20 59 11 65 -4 3 -10 2 -14 -2z'/>
                <path className='draw-me' d='M882 939 c4 -31 62 -69 106 -69 27 0 33 3 28 16 -3 9 -6 28 -6 42 0
                21 -2 24 -10 12 -5 -8 -7 -23 -4 -32 9 -31 -46 -17 -84 21 -31 31 -33 32 -30
                10z'/>
                <path className='draw-me' d='M1130 927 c0 -22 4 -28 16 -23 9 3 27 6 40 6 15 0 24 6 24 15 0 12
                -4 13 -19 5 -25 -13 -41 -13 -41 0 0 6 9 10 19 10 11 0 23 4 26 10 3 5 -9 9
                -30 8 -32 -1 -35 -4 -35 -31z'/>
                <path className='draw-me' d='M320 927 c0 -30 40 -61 92 -72 18 -4 28 -13 28 -25 0 -27 16 -33 56
                -20 30 10 51 7 159 -22 117 -31 124 -34 118 -55 -4 -20 -1 -23 22 -23 14 0 35
                5 46 10 15 8 43 5 116 -15 201 -53 203 -54 203 -81 0 -28 29 -32 69 -10 20 11
                36 10 114 -10 50 -13 100 -24 110 -24 15 0 17 -4 11 -17 -4 -10 -10 -48 -12
                -84 -3 -56 0 -74 21 -115 46 -90 124 -144 207 -144 77 0 155 59 180 136 13 40
                8 128 -10 148 -5 7 -7 16 -3 19 3 4 2 7 -4 7 -6 0 -8 -29 -5 -77 5 -79 -4 -98
                -13 -28 -10 75 -75 168 -139 199 -38 18 -103 21 -126 6 -11 -7 -2 -9 31 -8 58
                1 119 -19 119 -38 0 -9 4 -13 8 -10 11 6 23 -4 48 -39 62 -91 56 -200 -13
                -255 -30 -24 -109 -25 -153 -2 -24 12 -23 12 15 7 72 -9 134 40 149 120 5 25
                5 27 -4 10 -9 -18 -10 -17 -7 8 3 15 1 27 -4 27 -4 0 -6 9 -4 20 2 11 1 18 -3
                16 -4 -3 -13 7 -20 22 -11 27 -112 90 -112 71 0 -5 -10 -9 -22 -9 -27 -1 -44
                -13 -77 -55 l-24 -30 6 40 c3 22 8 48 12 58 6 13 4 16 -10 11 -9 -4 -20 -2
                -24 4 -3 6 -53 22 -111 37 -58 14 -113 28 -124 32 -13 3 -16 1 -11 -7 5 -9 1
                -10 -16 -6 -13 3 -18 3 -11 0 15 -7 16 -24 2 -24 -6 0 -16 11 -22 25 -9 20
                -28 30 -92 48 -187 55 -266 68 -266 47 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10
                0 6 7 10 15 10 8 0 15 4 15 8 0 5 -33 16 -72 26 -40 9 -98 23 -128 32 -30 9
                -79 15 -107 15 -41 -1 -51 2 -47 12 3 10 -14 21 -56 37 -33 12 -67 32 -75 44
                -15 20 -15 20 -15 -7z m1347 -397 c42 -25 77 -93 69 -133 -10 -50 -43 -77 -92
                -77 -36 0 -47 5 -78 40 -31 34 -36 47 -36 88 1 49 11 71 45 90 29 17 56 14 92
                -8z'/>
                <path className='draw-me' d='M1650 507 c0 -7 -15 -28 -34 -46 -37 -36 -38 -61 -1 -61 26 0 41 15
                25 25 -13 8 -13 25 0 25 6 0 10 -7 10 -16 0 -12 7 -14 30 -9 26 6 30 4 30 -15
                0 -23 -31 -41 -38 -22 -2 7 -8 10 -13 6 -15 -9 0 -24 26 -24 40 0 47 53 15
                105 -22 35 -50 53 -50 32z m50 -54 c0 -18 -10 -16 -26 5 -22 28 -18 48 6 27
                11 -10 20 -24 20 -32z'/>
                <path className='draw-me' d='M1268 937 c-28 -11 -38 -27 -17 -27 5 0 8 -4 5 -9 -3 -5 1 -15 10
                -22 12 -10 23 -9 60 9 30 15 54 20 72 16 l27 -6 -23 17 c-21 15 -27 15 -67 2
                -42 -15 -55 -13 -55 6 0 5 11 7 24 5 15 -3 27 0 30 8 6 17 -26 17 -66 1z'/>
                <path className='draw-me' d='M2048 944 c-4 -4 -5 -18 -1 -32 4 -19 0 -29 -18 -43 -13 -10 -19 -19
                -13 -19 23 0 51 44 45 73 -3 16 -9 25 -13 21z'/>
                <path className='draw-me' d='M1857 933 c-23 -6 -52 -62 -39 -75 7 -7 12 -3 17 11 10 32 39 27 32
                -6 l-5 -27 19 25 c27 35 12 80 -24 72z'/>
                <path className='draw-me' d='M2374 918 c-12 -35 -13 -47 -4 -53 4 -2 10 12 13 32 7 43 2 55 -9 21z'/>
                <path className='draw-me' d='M1038 918 c-9 -22 23 -55 60 -63 19 -4 32 -3 32 3 0 6 -14 13 -30 17
                -18 4 -36 17 -44 32 -9 19 -14 22 -18 11z'/>
                <path className='draw-me' d='M1940 916 c0 -21 15 -28 44 -20 34 8 22 34 -15 34 -19 0 -29 -5 -29
                -14z'/>
                <path className='draw-me' d='M801 903 c-1 -13 5 -23 12 -23 30 0 57 14 57 28 0 12 -3 13 -12 4
                -16 -16 -45 -15 -51 1 -3 6 -6 2 -6 -10z'/>
                <path className='draw-me' d='M1182 889 c-13 -5 -28 -7 -33 -3 -5 3 -9 -2 -9 -10 0 -24 47 -20 60
                4 6 11 9 20 8 19 -2 0 -14 -4 -26 -10z'/>
                <path className='draw-me' d='M2270 893 c16 -7 12 -33 -6 -40 -9 -3 -20 0 -25 8 -6 10 -9 8 -9 -10
                0 -19 4 -22 20 -17 11 3 29 9 40 12 14 5 20 2 20 -10 0 -9 5 -16 11 -16 7 0 9
                -9 6 -22 -6 -20 -4 -20 20 5 27 26 26 50 0 29 -11 -9 -19 -4 -38 25 -13 20
                -30 38 -37 39 -7 1 -8 0 -2 -3z'/>
                <path className='draw-me' d='M1226 819 l7 -61 -29 4 c-26 3 -29 7 -32 46 -3 43 -10 48 -47 32 -20
                -9 -20 -9 0 -9 15 -1 21 -9 22 -34 3 -38 37 -70 64 -62 11 4 26 0 35 -9 18
                -18 38 -20 63 -6 14 7 23 5 36 -10 10 -11 28 -20 40 -20 12 0 25 -3 29 -7 3
                -4 14 -8 24 -9 9 -2 36 -8 59 -15 32 -10 46 -10 54 -2 8 8 -15 17 -90 34 -90
                21 -101 25 -99 44 2 11 -2 19 -9 18 -6 -1 -17 11 -25 27 -15 34 -43 42 -34 10
                3 -11 9 -29 12 -40 6 -21 -9 -26 -40 -14 -13 5 -15 16 -10 54 6 47 -6 90 -27
                90 -6 0 -8 -22 -3 -61z'/>
                <path className='draw-me' d='M1400 864 c0 -12 -6 -15 -26 -10 -19 5 -24 4 -18 -5 5 -9 2 -10 -9
                -6 -27 10 -20 -16 12 -47 25 -24 32 -26 50 -16 19 10 28 5 75 -34 29 -26 58
                -46 64 -46 6 0 16 -8 21 -17 10 -17 10 -17 11 0 0 9 5 17 11 17 5 0 7 -4 4
                -10 -4 -6 7 -10 24 -10 23 0 31 5 31 18 0 27 -7 31 -44 22 -27 -6 -35 -4 -45
                12 -6 10 -16 16 -21 13 -6 -3 -10 -1 -10 4 0 6 -5 11 -10 11 -6 0 -28 12 -50
                26 -22 15 -46 23 -57 20 -10 -3 -24 -2 -31 2 -9 6 -6 14 13 30 26 22 33 42 15
                42 -5 0 -10 -7 -10 -16z'/>
                <path className='draw-me' d='M1605 853 c-8 -34 1 -41 24 -16 18 19 18 20 1 31 -15 9 -19 7 -25
                -15z'/>
                <path className='draw-me' d='M1019 854 c-14 -17 -3 -44 22 -53 12 -4 12 -2 0 12 -9 11 -10 17 -2
                17 13 0 15 26 2 34 -5 3 -15 -1 -22 -10z'/>
                <path className='draw-me' d='M2152 846 c-1 -18 5 -25 25 -31 15 -3 30 -1 37 6 8 9 7 10 -6 6 -14
                -5 -18 -1 -18 18 0 34 -35 35 -38 1z'/>
                <path className='draw-me' d='M1526 835 c4 -8 11 -15 18 -15 6 0 5 -4 -3 -9 -29 -18 16 -61 65 -61
                31 0 12 19 -23 22 -44 4 -58 28 -16 28 27 1 27 1 8 16 -38 29 -55 36 -49 19z'/>
                <path className='draw-me' d='M1647 815 c-20 -41 -20 -45 -7 -45 5 0 12 11 16 25 3 14 10 25 16 25
                5 0 7 4 4 9 -4 5 0 12 6 14 9 4 8 6 -3 6 -8 1 -23 -15 -32 -34z'/>
                <path className='draw-me' d='M2111 843 c-1 -7 -8 -13 -16 -13 -11 0 -14 -7 -9 -25 6 -25 -10 -36
                -19 -12 -3 6 -6 0 -6 -15 -1 -26 18 -52 20 -26 0 8 3 7 7 -3 6 -13 11 -10 31
                15 14 17 18 25 11 19 -23 -21 -35 -15 -15 7 14 16 16 24 7 42 -6 13 -11 17
                -11 11z'/>
                <path className='draw-me' d='M1755 812 c14 -13 25 -30 25 -35 0 -6 5 -5 13 2 10 11 10 14 0 18 -7
                3 -13 11 -13 18 0 7 -11 15 -25 17 l-25 5 25 -25z'/>
                <path className='draw-me' d='M1695 814 c3 -11 -2 -29 -10 -40 -19 -25 -19 -54 0 -54 9 0 18 -7 21
                -15 8 -19 24 -20 24 -1 0 7 9 16 20 19 21 5 28 27 9 27 -5 0 -15 14 -20 30 -6
                17 -17 30 -24 30 -7 0 -16 6 -19 13 -3 6 -4 3 -1 -9z m32 -57 c-2 -37 -22 -35
                -23 3 0 31 5 42 16 35 4 -3 8 -20 7 -38z'/>
                <path className='draw-me' d='M960 796 c29 -18 80 -22 64 -6 -6 6 -28 13 -50 16 -39 5 -39 5 -14
                -10z'/>
                <path className='draw-me' d='M1976 780 c-10 -11 -19 -30 -20 -42 -1 -13 -7 -23 -16 -23 -8 0 -16
                -9 -18 -20 -2 -11 -9 -26 -14 -32 -7 -9 -7 -13 1 -13 6 0 17 14 26 30 15 29
                35 40 35 19 0 -6 -5 -19 -10 -30 -9 -15 -7 -19 5 -19 9 0 18 6 21 14 3 8 18
                16 35 20 31 6 40 26 12 26 -17 1 -17 1 0 11 9 5 17 19 17 29 0 26 -13 25 -25
                -2 -6 -11 -20 -25 -32 -29 -19 -7 -23 -5 -23 10 0 16 37 59 46 54 2 -2 7 -3
                12 -3 4 0 0 5 -8 10 -20 13 -22 12 -44 -10z'/>
                <path className='draw-me' d='M2168 793 c25 -5 30 -23 7 -23 -17 0 -20 -11 -5 -26 12 -12 1 -54
                -15 -54 -17 0 -25 23 -18 52 6 22 6 22 -11 6 -21 -22 -14 -53 17 -69 32 -18
                64 27 59 81 -4 36 -7 40 -31 39 -23 -2 -24 -2 -3 -6z'/>
                <path className='draw-me' d='M2361 771 c-6 -11 -17 -18 -25 -15 -10 4 -12 -2 -9 -21 3 -14 10 -28
                16 -32 6 -4 7 -1 2 6 -4 8 2 25 15 43 12 16 19 32 17 35 -3 2 -10 -5 -16 -16z'/>
                <path className='draw-me' d='M936 771 c-3 -5 8 -11 25 -15 50 -10 57 -6 20 9 -39 18 -38 17 -45 6z'/>
                <path className='draw-me' d='M1820 769 c0 -5 16 -9 35 -9 19 0 35 4 35 8 0 4 -16 8 -35 9 -19 1
                -35 -3 -35 -8z'/>
                <path className='draw-me' d='M1879 735 c-1 -6 -1 -14 0 -19 1 -4 -5 -19 -14 -32 -13 -20 -18 -22
                -29 -12 -16 16 -10 35 12 41 10 3 5 5 -10 6 -39 1 -41 0 -43 -35 -1 -25 2 -30
                15 -27 9 2 23 -3 31 -13 13 -16 11 -17 -28 -11 -48 8 -53 3 -22 -22 17 -14 31
                -16 68 -10 25 4 78 8 117 8 39 1 78 6 87 14 14 10 -3 11 -93 9 -122 -4 -125
                -2 -89 57 14 24 17 37 10 44 -6 6 -12 7 -12 2z'/>
                <path className='draw-me' d='M1946 545 c4 -11 21 -15 58 -15 47 1 49 2 32 15 -27 21 -97 20 -90 0z'/>
                <path className='draw-me' d='M1871 521 c-12 -8 -12 -12 -2 -25 18 -22 55 -22 47 0 -3 9 -6 20 -6
                25 0 12 -21 11 -39 0z'/>
                </g>
            </svg>

        </div>
    )
}

export default Bus