import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useState, useEffect, useRef } from 'react'
import Loader from '../Loader/loader'

import emailjs from '@emailjs/browser'

import PhoneNumber from './PhoneNumber'

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const refForm = useRef()

    useEffect(() => {
      const timeout = setTimeout(() => {
        setLetterClass('text-animate-hover')
      }, 3000)
      return () => clearTimeout(timeout)
    }, [])

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm(
            'service_tzymy2d',  // service id
            'template_contact', // template id
            refForm.current,
            'uoxQS0uDpmgmzOeBE' // public key
        )
        .then(
            () => {
                alert('Message sent successfully!')
                window.location.replace('/');
            },
            () => {
                alert('Failed to send the message, please try again later.')
            }
        )
    }
  
    return (
    <>
        <div className='container contact-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass}
                    letterArray={
                        ['C', 'O', 'N', 'T', 'A', 'C', 'T', ' ', 'U', 'S']
                    }
                    index={15} 
                    />
                </h1>
                <p>
                Our snowcone stand is perfect for a variety of events and venues. 
                Whether you're hosting a birthday party, wedding, festival, 
                or community event, we have you covered.
                </p>
                <div className='contact-form'>
                    <form ref={refForm} onSubmit={sendEmail}>
                        <ul>
                            <li className='half'>
                                <input type='text' 
                                name='name' 
                                placeholder='Name' 
                                required />
                            </li>
                            <li className='half'>
                                <input type='email' 
                                name='email' 
                                placeholder='Email Address' 
                                required />
                            </li>
                            <li className='phone-number'>
                                <PhoneNumber />
                            </li>
                            <li>
                                <select className='drop-down' name='contact-method' required>
                                    <option value='' disabled selected hidden>Preferred Contact Method:</option>
                                    <option value='email'>Email</option>
                                    <option value='text'>Text</option>
                                    <option value='phone'>Phone Call</option>
                                </select>
                            </li>
                            <li>
                                <input type='text' name='subject' placeholder='Subject' required />
                            </li>
                            <li>
                                <textarea type='text' name='message' placeholder='Message' required></textarea>
                            </li>
                            <li>
                                <input type='submit' className='flat-button' value='Send Message' />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
        <Loader />
    </>
    );
}

export default Contact