import React, { useState } from 'react'

import FirstTab from './AllTabs/FirstTab'
import SecondTab from './AllTabs/SecondTab'


const Tabs = () => {
    const [activeTab, setActiveTab] = useState('tab tab1')
    const handleTab1 = () => {
        setActiveTab('tab tab1')
    }
    const handleTab2 = () => {
        setActiveTab('tab tab2')
    }

  return (
    <div className='tabs'>

        <div className='nav-holder'>
            <div className='tabnav'>
                <div className={activeTab === 'tab tab1' ? 'tab active' : 'tab'}
                onClick={handleTab1}>
                    Flavors
                </div>
                <div className={activeTab === 'tab tab2' ? 'tab active' : 'tab'}
                onClick={handleTab2}>
                    Fusions
                </div>
            </div>
        </div>

        <div className='outlet'>
            {activeTab === 'tab tab1' ? <FirstTab /> : <SecondTab />}
        </div>

    </div>
  )
}
export default Tabs