import { Outlet } from 'react-router-dom';
import './index.scss';
import Sidebar from '../Sidebar';
import Snowflakes from '../Snowflakes'

const Layout = () => {
    return (
        <div className='App'>
            <Sidebar />
            <div id='page' className='page'>
                <div id='snow-container'>
                    <Snowflakes />
                </div>
                <span className='tags top-tags'>Now Open!</span>

                <Outlet />

            </div>
        </div>
    )
}

export default Layout;