import React from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
 
export default class GetNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = { phone: "" };
    }
    render() {
        return (
            <div>
                <PhoneInput
                    inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: false
                    }}
                    country={'us'}
                    disableCountryCode={true}
                    onlyCountries={['us']}
                    disableDropdown={true}
                    value={this.state.phone}
                    onChange={phone => this.setState({ phone })}
                />
            </div>
        )
    }
}
