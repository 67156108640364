import React from 'react'

import Simples from '../../../../assets/images/snowcones/SimpleSnowies.png'

const FirstTab = () => {
    return (
        <div className='first-tab-content'>
            <img src={Simples} alt='List of Flavors' />
        </div>
    )
}

export default FirstTab