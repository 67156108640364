import './index.scss';
import { Link, NavLink } from 'react-router-dom';
import SnowieLogo from '../../assets/images/snowie_cups_logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faHome, faUser, faImage } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const Sidebar = () => (
    <div className='navbar'>
        <span className='tags'>Now Open!</span>
        <Link className='logo' to='/'>
            <img src={SnowieLogo} alt='logo' />
        </Link>
        <nav>
            <NavLink exact ='true' activeclassname='active' to='/'>
                <FontAwesomeIcon icon={faHome} color='#4d4d4d' />
            </NavLink>
            <NavLink exact ='true' activeclassname='active' className='about-link' to='/about'>
                <FontAwesomeIcon icon={faUser} color='#4d4d4d' />
            </NavLink>
            <NavLink exact ='true' activeclassname='active' className='contact-link'to='/contact'>
                <FontAwesomeIcon icon={faMessage} color='#4d4d4d' />
            </NavLink>
            <NavLink exact ='true' activeclassname='active' className='photos-link'to='/photos'>
                <FontAwesomeIcon icon={faImage} color='#4d4d4d' />
            </NavLink>
            <NavLink exact ='true' activeclassname='active' className='fb-link'to='https://www.facebook.com/people/Snowie-Sherman/100093459170633/' target='_blank' rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} color='#4d4d4d' />
            </NavLink>
        </nav>
    </div>
)

export default Sidebar;