import './index.scss';

const AnimatedLetters = ({ letterClass, letterArray, index }) => {

    return (
        <span>
            {
                letterArray.map((letter, i) => (
                    <span key={letter + i} className={`${letterClass} _${i + index}`}>
                        {letter}
                    </span>
                ))
            }
        </span>
    )
};

export default AnimatedLetters;