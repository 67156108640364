import AnimatedLetters from '../AnimatedLetters'
import { useState, useEffect } from 'react'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIceCream, faCubesStacked,
        faCakeCandles, faMugHot, faDog,
        faHandshakeSimple} from '@fortawesome/free-solid-svg-icons'
import Loader from '../Loader/loader'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
    <div className='container about-page'>
      <div className='text-zone'>
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            letterArray={
              ['A', 'B', 'O', 'U', 'T']
            }
            index={15} 
          />
        </h1>
        
        <div className='cube-text-match'>
          <p className='intro'>Currently serving Grayson and some surrounding counties. Contact us to find out when we will be in your area.</p>
          
          <h2 className>Pet Friendly</h2><p>Ask about our Pup Cups to keep your friends cool in the summer!</p>

          <h2 className>Natural Flavors</h2><p>Natural and Sugar-Free versions of popular flavors. Try to taste the difference!</p>

          <h2 className>Community</h2><p>We believe in community. A portion of all profits are donated to local charities and non-profits.</p>

          <h2 className>The Best Ice</h2><p>Our ice is made daily from RO/DI water.</p>

          <h2 className>Winter Options</h2><p>During the colder months come grab a cup of Hot Cocoa, Iced Hot Chocolate, or a coffee</p>

          <h2 className>Birthdays</h2><p>Birthdays, corporate events, fundraisers. We love events and are eager to work with you.</p>
        </div>
      </div>

      <div className='stage-cube-container'>
        <div className='cube-spinner'>
          <div className='faces dog'>
            <FontAwesomeIcon icon={faDog} />
            <span className='cube-text'>Pet Friendly</span>
          </div>
          <div className='faces ice'>
            <FontAwesomeIcon icon={faCubesStacked} />
            <span className='cube-text'>Filtered Ice</span>
          </div>
          <div className='faces cake'>
            <FontAwesomeIcon icon={faCakeCandles} />
            <span className='cube-text'>Birthdays</span>
          </div>
          <div className='faces mug'>
            <FontAwesomeIcon icon={faMugHot} />
            <span className='cube-text'>Hot Cocoa</span>
          </div>
          <div className='faces cone'>
            <FontAwesomeIcon icon={faIceCream} />
            <span className='cube-text'>Natural Flavor</span>
          </div>
          <div className='faces shake'>
            <FontAwesomeIcon icon={faHandshakeSimple} />
            <span className='cube-text'>Community</span>
          </div>
        </div>
      </div>
    </div>
    <Loader />        
    </>
  );
}

export default About;