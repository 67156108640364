import { register } from 'swiper/element/bundle'
// import { useState, useEffect } from 'react'
// import AnimatedLetters from '../../AnimatedLetters';
import SharkAttack from '../../../assets/images/snowcones/SharkAttack.png'
import CoconutCraze from '../../../assets/images/snowcones/CoconutCraze.png'
import Pirate from '../../../assets/images/snowcones/Pirate.png'
import WalkDead from '../../../assets/images/snowcones/WalkDead.png'
import Unicorn from '../../../assets/images/snowcones/Unicorn.png'
import PeachMe from '../../../assets/images/snowcones/PeachMe.png'
import Mermaid from '../../../assets/images/snowcones/Mermaid.png'
import Dillicious from '../../../assets/images/snowcones/Dillicious.png'
import Java from '../../../assets/images/snowcones/Java.png'
import Mangonada from '../../../assets/images/snowcones/Mangonada.png'
import Skittles from '../../../assets/images/snowcones/Skittles.png'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-flip'
import 'swiper/css/autoplay'

register()

const Carousel = () => {
  // const [letterClass, setLetterClass] = useState('text-animate')
  // const sharkArray = ['S', 'H', 'A', 'R', 'K', ' ', 'A', 'T', 'T', 'A', 'C', 'K']
  // const coconutArray = ['C', 'O', 'C', 'O', 'N', 'U', 'T', ' ', 'C', 'R', 'A', 'Z', 'E']
  // const pirateArray = ['P', 'I', 'R', 'A', 'T', 'E', '\'', 'S', ' ', 'G', 'O', 'L', 'D']
  // const deadArray = ['W', 'A', 'L', 'K', 'I', 'N', 'G', ' ', 'D', 'E', 'A', 'D']
  // const unicornArray1 = ['U', 'N', 'I', 'C', 'O', 'R', 'N']
  // const unicornArray2 = ['P', 'O', 'T', 'I', 'O', 'N']
  // const peachArray1 = ['P', 'E', 'A', 'C', 'H', ' ', 'M', 'E', ',']
  // const peachArray2 = ['I', '\'', 'M', ' ', 'D', 'R', 'E', 'A', 'M', 'I', 'N', 'G']
  // const mermaidArray = ['M', 'E', 'R', 'M', 'A', 'I', 'D', ' ', 'M', 'I', 'S', 'T']
  // const pickleArray = ['D', 'I', 'L', 'L', 'I', 'C', 'I', 'O', 'U', 'S']
  // const javaArray = ['J', 'A', 'V', 'A', ' ', 'J', 'O', 'L', 'T']
  // const mangonadaArray = ['M', 'A', 'N', 'G', 'O', 'N', 'A', 'D', 'A']
  // const skittlesArray1 = ['S', 'K', 'I', 'T', 'T', 'L', 'E', ' ', 'M', 'E', ]
  // const skittlesArray2 = ['T', 'H', 'I', 'S']

  // useEffect(() => {
  //     const timeout = setTimeout(() => {
  //         setLetterClass('text-animate-hover')
  // }, 3000)
  // return () => clearTimeout(timeout)
  // }, [])

  return (
    <Swiper
      A11y={true}
      effect={'flip'}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      navigation={true}
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      autoplay={false}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className='swiper-card'>
          <img src={SharkAttack} alt='Shark Attack' />
          <div className='card-info'>
            <h1>Shark Attack</h1>
            {/* <AnimatedLetters letterClass={letterClass} 
                                    letterArray={sharkArray} 
                                    index={15} /> */}
            <p>Blue Raspberry, Tiger's Blood, Whipped Cream, Strawberry Puree, and Gummy Sharks</p>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className='swiper-card'>
          <img src={CoconutCraze} alt='Coconut Craze' />
          <div className='card-info'>
            <h1>Coconut Craze</h1>
            {/* <AnimatedLetters letterClass={letterClass}
                                    letterArray={coconutArray}
                                    index={15} /> */}
            <p>Coconut, Piña Colada, Coconut Cream, Whipped Cream, and Toasted Coconut</p>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className='swiper-card'>
          <img src={Pirate} alt='Pirate' />
          <div className='card-info'>
            <h1>Pirate's Gold</h1>
            {/* <AnimatedLetters letterClass={letterClass}
                                    letterArray={pirateArray}
                                    index={15} /> */}
            <p>Banana, Mango, Piña Colada, Pineapple Puree, Whipped Cream, Gold Sprinkles, Gold Coin</p>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className='swiper-card'>
          <img src={WalkDead} alt='Walking Dead' />
          <div className='card-info'>
            <h1>Walking Dead</h1>
            {/* <AnimatedLetters letterClass={letterClass}
                                    letterArray={deadArray}
                                    index={15} /> */}
            <p>Zombie Anti-Virus, Toxic Waste, Whipped Cream, Strawberry Puree, Sprinkles, Gummy Teeth</p>
          </div>
        </div>    
      </SwiperSlide>

 
      <SwiperSlide>
      <div className='swiper-card'>
          <img src={Unicorn} alt='Unicorn Potion' />
          <div className='card-info'>
            <h1>Unicorn Potion</h1>
            {/* <AnimatedLetters letterClass={letterClass}
                                    letterArray={unicornArray1}
                                    index={15} /><br />
            <AnimatedLetters letterClass={letterClass}
                                    letterArray={unicornArray2}
                                    index={15} /> */}
            <p>Blue Cotton Candy, Watermelon, Whipped Cream, Sprinkles, Rainbow Sucker</p>
          </div>
        </div>   
      </SwiperSlide>

      <SwiperSlide>
        <div className='swiper-card'>
            <img src={PeachMe} alt='Peach Me, I am Dreaming' />
            <div className='card-info'>
              <h1>Peach Me,</h1>
              <h1>I'm Dreaming</h1>
              {/* <AnimatedLetters letterClass={letterClass}
                                      letterArray={peachArray1}
                                      index={15} /><br />
              <AnimatedLetters letterClass={letterClass}
                                      letterArray={peachArray2}
                                      index={15} /> */}
              <p>Peach, Peach Puree, Peach Sprinkles, Whipped Cream, Peach Rings</p>
            </div>
          </div>  
        </SwiperSlide>

      <SwiperSlide>
        <div className='swiper-card'>
            <img src={Mermaid} alt='Mermaid Mist' />
            <div className='card-info'>
              <h1>Mermaid Mist</h1>
              {/* <AnimatedLetters letterClass={letterClass}
                                      letterArray={mermaidArray}
                                      index={15} /> */}
              <p>Birthday Cake, Blue Cotton Candy, Whipped Cream, Sprinkles, Cotton Candy Topper</p>
            </div>
          </div> 
      </SwiperSlide>

      <SwiperSlide>
        <div className='swiper-card'>
            <img src={Dillicious} alt='Dillicious' />
            <div className='card-info'>
              <h1>Dillicious</h1>
              {/* <AnimatedLetters letterClass={letterClass}
                                      letterArray={pickleArray}
                                      index={15} /> */}
              <p>Real Pickle Juice, Tajín, Dill Pickle Salt, and a Pickle Spear</p>
            </div>
          </div> 
      </SwiperSlide>

      <SwiperSlide>
      <div className='swiper-card'>
          <img src={Java} alt='Java Jolt' />
          <div className='card-info'>
            <h1>Java Jolt</h1>
            {/* <AnimatedLetters letterClass={letterClass}
                                    letterArray={javaArray}
                                    index={15} /> */}
            <p>Coffee, Whipped Cream, Caramel and Chocolate Drizzle. Optional: Hazelnut, Coconut, and Vanilla Cream</p>
          </div>
        </div>   
      </SwiperSlide>

      <SwiperSlide>
      <div className='swiper-card'>
          <img src={Mangonada} alt='Mangonada' />
          <div className='card-info'>
            <h1>Mangonada</h1>
            {/* <AnimatedLetters letterClass={letterClass}
                                    letterArray={mangonadaArray}
                                    index={15} /> */}
            <p>Mango, Chamoy, Mango Puree, Tajín</p>
          </div>
        </div>   
      </SwiperSlide>

      <SwiperSlide>
        <div className='swiper-card'>
            <img src={Skittles} alt='Skittle Me This' />
            <div className='card-info'>
              <h1>Skittle Me This</h1>
              {/* <AnimatedLetters letterClass={letterClass}
                                      letterArray={skittlesArray1}
                                      index={15} /><br />
              <AnimatedLetters letterClass={letterClass}
                                      letterArray={skittlesArray2}
                                      index={15} /> */}
              <p>Tiger's Blood, Banana, Blue Raspberry, Whipped Cream, Skittles, Sour Rainbow Gummy</p>
            </div>
          </div>  
        </SwiperSlide>

    </Swiper>
  )
}

export default Carousel